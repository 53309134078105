import { Outlet } from 'react-router-dom'


import logo from '../logo.png'

export default function AppLayout() {

    const loggedIn = localStorage.getItem("token")

    return (
        <div className='bg-no-repeat bg-cover flex min-h-screen min-w-screen bg-[url(https://images.unsplash.com/photo-1596807995197-e8e64154a30c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2071&q=80)]'>

            <div className='flex flex-col flex-1'>

                <header className="flex justify-between w-full mx-auto max-w-7xl">

                    <div id="logo">
                        <a href="/" className=""><img
                            src={logo} alt="Circul8 Logo" /></a>
                    </div>

                    <div id="sm:hidden">
                        <svg className="svg-trigger" viewBox="0 0 100 100">
                            <path
                                d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20">
                            </path>
                            <path d="m 30,50 h 40"></path>
                            <path
                                d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20">
                            </path>
                        </svg>
                    </div>

                    <ul className="flex items-center space-x-8">
                        <li className="menu-item current"><a className="text-sm tracking-wide uppercase text-neutral-100 text-light hover:text-neutral-200" href="/">
                            <div>Search</div>
                        </a>
                        </li>
                        {loggedIn && <li className="text-sm tracking-wide uppercase text-neutral-100 text-light hover:text-neutral-200"><a className="menu-link"
                            href="/profile?id=own&listing=true">
                            <div>List Job</div>
                        </a></li>}
                        {loggedIn && <li className="text-sm tracking-wide uppercase text-neutral-100 text-light hover:text-neutral-200"><a className="menu-link"
                            href="/profile?id=own">
                            <div>My Profile</div>
                        </a></li>}
                        {loggedIn && <li className="text-sm tracking-wide uppercase text-neutral-100 text-light hover:text-neutral-200"><a className="menu-link"
                            href="/profile?id=own&invite=true">
                            <div>Invite</div>
                        </a></li>}
                        <li className="text-sm tracking-wide uppercase text-neutral-100 text-light hover:text-neutral-200"><a className="menu-link" href="https://92227aaf.sibforms.com/serve/MUIEAGgYidvEV8zVwsS88rALXyaWuAzyCppEnCk93a_ns252ochk9sXPkvj77aVQEwGPfMeqVky1YyjqhXEV6PSlqdYNFJcA78BCBo3Rjkw_3slf5y5GrJBLsBh1RQkWLncWYZ6UthYWsgf9JXSI6VhV0sVKQFrttv9-xJ2nlN8k-j28oC4DPdZ20zqEsZoy2V_sn0FVjGj3qyie" target="_blank">
                            <div>Newsletter</div>
                        </a></li>
                        <li className="text-sm tracking-wide uppercase text-neutral-100 text-light hover:text-neutral-200"><a className="menu-link" href="mailto:support@circul8.me">
                            <div>Contact</div>
                        </a></li>
                        {loggedIn && <li className="text-sm tracking-wide uppercase text-neutral-100 text-light hover:text-neutral-200"><a className="menu-link"
                            href="/logout">
                            <div>Logout</div>
                        </a></li>}
                        {!loggedIn && <li className="text-sm tracking-wide uppercase text-neutral-100 text-light hover:text-neutral-200"><a className="menu-link" href="/login">
                            <div>Login</div>
                        </a></li>}
                     
                    </ul>
                </header>

                <div className='flex flex-1 min-h-[90vh]'>
                    <Outlet></Outlet>
                </div>

                <footer id="footer" class="dark">
                    <div class="container">
                        <div class="footer-widgets-wrap">

                            <div class="row col-mb-50">
                                <div class="col-lg-12 order-last order-lg-first">

                                    <div class="widget clearfix">

                                        <img src={logo}
                                            STYLE="position: relative; opacity: 0.85; left: -10px; max-height: 80px; margin-bottom: 20px;"
                                            alt="Footer Logo" />

                                        <div class="line" STYLE="margin: 30px 0;"></div>

                                        <div STYLE="display:flex">
                                            <p class="ls1 fw-light" STYLE="opacity: 0.6; font-size: 13px;">Copyrights &copy;
                                                2023
                                                Circul8</p>
                                            <a href="/privacy" class="ls1 fw-light"
                                                STYLE="opacity: 0.6; font-size: 13px; margin-left:20px">Privacy Policy</a>
                                            <a href="/terms" class="ls1 fw-light"
                                                STYLE="opacity: 0.6; font-size: 13px; margin-left:20px">Terms & Conditions</a>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </footer>
            </div>
        </div>
    )
}
