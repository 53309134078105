import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';



import AuthLayout from './layout';


export default function Rigester() {
      const queryParams = new URLSearchParams(window.location.search)
         const invite = queryParams.get('invite')
         console.log(invite)
    const navigate = useNavigate()
    const [values, setValues] = useState({
    })
    const [error, setError] = useState('')
    const [searchParams, setSearchParams] = useSearchParams()

    async function submit() {
        await fetch(`${process.env.REACT_APP_API_URL}/auth/register`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
                ...values,
                inviteCode: invite,
            }),
        })
            .then((res) => {
                if (!res.ok) {
                    return res.json().then((data) => {
                        throw new Error(data.error)
                    })
                }
                return res.json()
            })
            .then((data) => {
                console.log(data)
                  return data
            })
            .then((x) => {
                if (x.token) {
                    localStorage.setItem('token', x.token)
                    window.location.replace('/')
                } else {
                    setError('Login failed. Please check your details and try again.')
                }
            })
            .catch((error) => {
                // Handle errors and display the error message
                console.error(error)
                setError(error.message)
            })
    }
console.log(values);
    return (
        <AuthLayout>
                <div className='text-xl font-medium text-center text-neutral-600'>Register</div>

            <div className='space-y-6'>
                <div>
                    <label className='block text-sm font-medium text-center text-neutral-600'>{error}</label>
                </div>
                   <div>
                    <label htmlFor='name' className='block text-sm font-medium text-neutral-600'>
                        Name
                    </label>
                    <div className='mt-1'>
                        <input value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} id='name' name='email' type='name' autoComplete='name' required className='block w-full px-3 py-2 border rounded shadow-sm appearance-none border-neutral-300 placeholder-neutral-400 focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm' />
                    </div>
                </div>
                <div>
                    <label htmlFor='email' className='block text-sm font-medium text-neutral-600'>
                        Email address
                    </label>
                    <div className='mt-1'>
                        <input value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })} id='email' name='email' type='email' autoComplete='email' required className='block w-full px-3 py-2 border rounded shadow-sm appearance-none border-neutral-300 placeholder-neutral-400 focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm' />
                    </div>
                </div>

                <div>
                    <label htmlFor='password' className='block text-sm font-medium text-neutral-600'>
                        Password
                    </label>
                    <div className='mt-1'>
                        <input value={values.password} onChange={(e) => setValues({ ...values, password: e.target.value })} id='password' name='password' type='password' autoComplete='current-password' required className='block w-full px-3 py-2 border rounded shadow-sm appearance-none border-neutral-300 placeholder-neutral-400 focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm' />
                    </div>
                </div>
                 <div>
                    <label htmlFor='invite' className='block text-sm font-medium text-neutral-600'>
                        Invite
                    </label>
                    <div className='mt-1'>
                        <input value={invite}  id='invite' name='password' type='invite' autoComplete='current-password' required className='block w-full px-3 py-2 border rounded shadow-sm appearance-none border-neutral-300 placeholder-neutral-400 focus:border-neutral-500 focus:outline-none focus:ring-neutral-500 sm:text-sm' />
                    </div>
                </div>
                <div>
                    <button onClick={() => submit()} type='button' className='flex justify-center w-full px-4 py-2 font-semibold text-white bg-green-500 border border-transparent rounded shadow-sm text-md hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2'>
                        Register
                    </button>
                </div>
            </div>
        </AuthLayout>
    )
}