import React from 'react';

export default class Terms extends React.Component {

    render() {
        return <div className="w-full m-auto max-w-7xl">
            
            <div
				STYLE="background:white; padding:10px; margin-left: auto; margin-right: auto;">
				<h1>Terms & Conditions </h1>
				<h3>Definitions</h3>

				<p>"C8" – means Circul8.me Limited.</p>

				<p>"User" – means any person who visits the Website for any purpose.</p>

				<p>"Website" – means www.circul8.me</p>

				<h3>Privacy Policy</h3>

				<p>The C8 Privacy Policy is incorporated into and forms part of these Terms and Conditions and can be
				viewed by clicking on "Privacy Policy" on the Website homepage.</p>

				<h3>General Notes</h3>

				<p>User information provided through the Website will be processed by C8 in accordance with the Privacy
				Policy.</p>

				<h3>Intellectual Property</h3>

				<p>Subject to exceptions as may from time to time be notified in these Terms and Conditions, all content
				included on the Website, unless lawfully uploaded by Users, including, but not limited to, text,
				graphics, logos, icons, images, sound clips, video clips, data compilations, page layout, underlying
				code and software is the property of C8 and/or its affiliates or other relevant third parties and/or
				persons. By continuing to use the Website Users acknowledge that such material is protected by
				applicable United Kingdom and International intellectual property and other laws.
				Users may not reproduce, copy, distribute, store or in any other fashion re-use material from the
				Website unless otherwise indicated on the Website and/or in these Terms and Conditions or unless given
				express written permission to do so by C8.</p>

				<h3>Third Party Intellectual Property</h3>

				<p>Unless otherwise expressly indicated, all rights in product images and descriptions including, but not
				limited to, Copyright and Trademarks, belong to the manufacturers or distributors of such products
				and/or descriptions as may be applicable.
				Users may not reproduce, copy, distribute, store or in any other fashion re-use such material unless
				otherwise indicated on the Website or unless given express written permission to do so by the relevant
				manufacturer or supplier.</p>

				<h3>Website Links</h3>

				<p>The Website may contain links to other websites not owned or controlled by C8 Whilst C8 will not use
				such links where it has actual knowledge that the relevant website has inappropriate content or may be
				likely to cause any damage to User it cannot and does not accept any liability whatsoever for the
				content and/or operation of any such website and hereby disclaims all liability for any direct or
				indirect loss or damage, foreseeable or otherwise, of any kind arising out of or in connection with a
				Users access to or use of any such website.
				The inclusion of a link to any other website on the Website does not in any circumstance constitute any
				endorsement of such other website or of any person in control of such other website.</p>

				<h3>Changes to these Terms and Conditions</h3>

				<p>C8 reserves the right to change the Website, its content or these Terms and Conditions at any time. User
				will be bound by any changes to the Terms and Conditions from the next time the User uses the Website
				following the changes. If C8 is required by law to make any changes to these Terms and Conditions, Users
				will be deemed to have accepted such changes from the time at which the relevant law comes into force.</p>

				<h3>Previous Terms and Conditions</h3>

				<p>In the event of any conflict between these Terms and Conditions and any prior versions thereof, the
				provisions of these Terms and Conditions shall prevail unless expressly stated otherwise.
				Limitation of Liability</p>

				<p>Without prejudice to the other disclaimers and limitations contained in these Terms and Conditions:
				To the maximum extent permitted by law, C8 accepts no liability for any direct or indirect loss or
				damage, foreseeable or otherwise, including any indirect, consequential, special or exemplary damages
				arising from the use of the Website or any information contained therein. Users should be aware that
				they use the Website and its content entirely at their own risk.
				Nothing in these Terms and Conditions excludes or restricts C8's liability for death or personal injury
				resulting from any negligence or fraud on the part of C8.
				Nothing in these Terms and Conditions excludes or restricts C8's liability for any direct or indirect
				loss or damage arising out of the incorrect delivery of any goods or out of reliance on incorrect
				information included on the Website.
				Whilst every effort has been made to ensure that these Terms and Conditions adhere strictly with the
				relevant provisions of the Unfair Contract Terms Act 1977, in the event that any of these terms are
				found to be unlawful, invalid or otherwise unenforceable, that term is to be deemed severed from these
				terms and conditions and shall not affect the validity and enforceability of the remaining terms and
				conditions. This term shall apply only within jurisdictions where a particular term is illegal.</p>

				<h3>Disclaimer</h3>

				<p>The information and services on this website, www.circul8.me, is not directed at residents of the United
				States or any particular country outside the UK and it is not intended for distribution to, or use by,
				any person in any country or jurisdiction where such use would conflict with local regulations or laws.
				It is the responsibility of users of the Website to understand the terms of and comply with, any local
				law or regulations to which they are subject.</p>

				<h3>Law and Jurisdiction</h3>

				<p>These Terms and Conditions and the relationship between Users and C8 shall be governed by and construed
				in accordance with the Laws of England and Wales and C8 and all Users agree to submit to the exclusive
				jurisdiction of the Courts of England and Wales.</p>

			</div>

        </div>

    }
}