import React from 'react';

export default class Privacy extends React.Component {

    render() {
        return <div className="w-full m-auto max-w-7xl">
            <div STYLE="background:white; padding:10px; margin-left: auto; margin-right: auto;">
				<h1>Privacy Policy</h1>
				<h3>Definitions</h3>

				<p>Terms in this Privacy Policy have the same meaning as in the Terms and Conditions unless otherwise
					stated.</p>

				<h3>Introduction</h3>

				<p>This Privacy Policy, when the Website is accessed by a User, is thereby accepted by the User as
					setting
					out how Circul8.me Limited ("C8") is allowed to lawfully collect, process and use information which
					identifies a User ("Personal Information"). Users must read this Privacy Policy in conjunction with
					the
					C8 Terms and Conditions, of which this Privacy Policy forms a part.
					C8 is committed to protecting the privacy and security of Personal Information. This Privacy Policy
					describes the privacy practices of EDL and also expresses C8's approach to complying with the data
					protection laws which apply in the United Kingdom.</p>

				<p>If a User has questions or concerns regarding this Privacy Policy, the User should email:
					info@mutant-robot.com putting "Privacy Policy Query" in the subject box.
					Use of Personal Information</p>

				<h3>GENERAL PRINCIPLES</h3>

				<p>C8 applies the following principles to the use of Personal Information:
					Not to supply Personal Information to third parties for commercial exploitation without the User's
					prior
					consent.
					Reserve the right to search for, extract and use information which is not Personal Information, for
					example: recorded preferences to Users; Users' choices on surveys; information from the cookie
					sampler
					referred to below; and other demographic and preference information. Use of such non personalised
					information may be for commercial purposes. At no time will any such search, extraction or use
					involve
					Personal Information.
					Provide Personal Information to a third party for non-commercial purposes only in circumstances
					provided
					for in this Privacy Policy.</p>

				<h3>USE OF COOKIES</h3>

				<p>C8 uses small files placed on a User's computer when the User visits the Website known as "cookies"
					to
					gather non-personal information regarding the number and frequency of visitors to the Website. Both
					the
					cookies and the embedded code provide general site statistical information about visits to pages,
					the
					duration of individual page view, paths taken by visitors through the Website and other general
					non-personal information. C8 uses this type of information to help it improve the Services to its
					Users.</p>

				<h3>CREDIT, DEBIT OR CHARGE CARD INFORMATION</h3>

				<p>C8 may use Payment Processors to process credit card payments on the Website. Payment Processors
					authorise payment directly, which means that Users' credit card details would never be revealed to
					C8
					Payment Processors may transfer Personal Information to its agents and other
					third parties only to the extent reasonably necessary for it to provide credit card payment
					processing
					services to C8 or for the purposes of prevention of fraud or other criminal detection. Payment
					Processors may transfer to, process and hold Users' credit card information in countries either
					within
					or outside the European Economic Area only in connection with performing its card payment processing
					functions.</p>

				<h3>LINKS TO THIRD PARTY SITES</h3>

				<p>The Website contains links to other websites. C8 is not responsible for and will have no liability to
					any User relating to or arising out of the privacy practices, or the content of, such other websites
					and
					Users.</p>

				<h3>COMMUNICATIONS FROM C8</h3>

				<p>C8 may generate regular and occasional e-mails, including newsletters. Users can choose to not
					receive
					such messages by clicking on the Unsubscribe link at the bottom of each message.</p>
				<h3>AFFILIATE COMPANIES OF C8 LIMITED AND SERVICE PROVIDERS</h3>

				<p>C8 may occasionally share Personal Information with subsidiaries and other companies in the C8 group,
					but only for the purposes set out in this Privacy Policy.
					If all of the assets which C8 uses to operate its business (or substantially all of them) are
					acquired
					by a third party, C8 may transfer Personal Information to that third party. That third party will be
					obliged to honour in their entirety all aspects of this Privacy Policy.
					C8 may transfer Personal Information to third party outsourced service providers or facilities
					managers
					for the purpose of providing, or assisting it to provide, the C8 Service to Users. That third party
					will
					be obliged to honour in their entirety all aspects of this Privacy Policy.</p>
				<h3>PLACE OF PROCESSING</h3>

				<p>At present, all Personal Information is held on and processed by servers situated in the United
					States
					of America. If in future C8 decides to locate any part of its operations in a country outside the
					United
					States of America (USA) or to use a service provider located outside the USA, C8 will notify Users
					of
					such decision via the Website and by continuing to use the Website, Users are deemed to agree to the
					transfer of Personal Information to any such country notified through the Website for the purpose of
					enabling C8 or third party services providers (as applicable) to undertake operations from that
					country
					relating to the provision of the C8 Service.</p>
				<h3>SECURITY</h3>

				<p>C8 has in place and keeps under reasonable review, appropriate technical and organisational
					(including
					physical, electronic and management practice) measures to protect Personal Information from unlawful
					processing and to prevent accidental loss, destruction and/or damage of Personal Information.</p>

				<h3>DISCLOSURE FOR LEGAL REASONS</h3>

				<p>C8 has the right to disclose any relevant Personal Information to authorities that make a legally
					compliant request for such disclosure.</p>
				<h3>DATA RETENTION AND DATA PROTECTION</h3>

				<p>Personal Information is retained only as long as is necessary for the purposes set out in the C8
					Terms
					and Conditions and this Privacy Policy, or as required by applicable law. Any Personal Information,
					the
					retention of which is not so necessary or required, will be permanently deleted or destroyed.
					Further information about data protection laws is available on the Information Commissioner's
					website at dataprotection.gov.uk. C8's Data Protection Notification is also available to view on the
					Commissioner's website.</p>

				<h3>OPTING OUT</h3>

				<p>Users may have registration details and any other Personal Information Users have provided on the
					Website removed at any time. To do so Users should please email info@3dmedia.info putting "Data
					Protection Opt-Out" in the subject box. Please be aware that, once removed, any Personal Information
					so
					removed cannot be recovered.
					Users have the opportunity to opt-out of receiving e-mailed information from C8. This opt-out can be
					effected by making appropriate selections from the "Unsubscribe" link in any newsletter we send you.
				</p>

				<h3>Contacting C8</h3>

				<p>Users can ask questions about this Privacy Policy, the practices of this Website, or dealings with
					C8,
					or request from C8 a copy of any Personal Information or a description of that Personal Information
					by
					emailing info@circul8.me. Please note that under English law C8 is entitled to charge a small sum
					fee
					for providing any Personal Information.</p>

				<h3>Changes to Privacy Policy</h3>

				<p>C8 reserves the right to make changes to this Privacy Policy as it may deem necessary and/or to give
					effect to any relevant changes in any applicable law. Any changes to this Privacy Policy will be
					posted
					to the Website and Users are deemed to accept such changes upon such posting, therefore Users are
					encouraged to view this Privacy Policy at frequent intervals whether or not they are using the
					Website
					at that time.</p>

			</div>
        </div>

    }
}