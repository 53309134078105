import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';



import Home from './app/home';
import AppLayout from './app/layout';
import Privacy from './app/privacy';
import Profile from './app/profile';
import Terms from './app/terms';
import Login from './auth/login';
import Logout from './auth/logout'
import Register from './auth/register'
export default function App() {
    return (<>
        <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/logout' element={<Logout />} />
             <Route path='/register' element={<Register />} />
            <Route path='/' element={<AppLayout />}>
                <Route index element={<Home />} />
                <Route path='/profile' element={<Profile />} />
                <Route path='/privacy' element={<Privacy />} />
                <Route path='/terms' element={<Terms />} />
            </Route>
        
        </Routes>

            <ToastContainer />
            </>
    )
}