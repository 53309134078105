import logo from '../logo.png'

export default function AuthLayout({ children }) {
    return (
        <div className='bg-no-repeat bg-cover min-h-screen min-w-screen flex flex-col h-screen bg-slate-50 bg-[url(https://images.unsplash.com/photo-1532005051008-7bdebde54683?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1163&q=80)]'>
            <div className='flex flex-col justify-center flex-1 max-h-screen'>
                <div className='sm:mx-auto sm:w-full sm:max-w-md'>
                    <img className='w-64 h-auto mx-auto mb-2' src={logo} alt='' />
                </div>
                <div className='mt-4 sm:mx-auto sm:w-full sm:max-w-lg'>
                    <div className='px-4 py-8 bg-white rounded shadow sm:px-10'>{children}</div>
                </div>
            </div>
        </div>
    )
}
