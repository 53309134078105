import React from 'react';
import Select from 'react-select';

export default class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = { categories: [], searchResults: [], name: "", job: "", circles: [], onlyAvailable: false, searchType: 'person' };
    }

    componentDidMount() {
        fetch(process.env.REACT_APP_API_URL + "/jobs")
            .then(res => { if (res.status === 401) window.location.replace('/login'); return res.json() })
            .then(
                (result) => {
                    this.setState({
                        categories: result
                    });
                },
                (error) => {
                    console.log(error)
                }
            )
    }

    search() {
        if (localStorage.token) {
            let searchString = (this.state.name ? this.state.name : 'null') + '/' + (this.state.job ? this.state.job : 'null') + '/' + this.state.onlyAvailable
            fetch(process.env.REACT_APP_API_URL + "/public/search/" + searchString, {
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.token
                }),
            })
                .then(res => { if (res.status === 401) window.location.replace('/login'); return res.json() })
                .then(
                    (result) => {
                        if (!result.searchResult || !result.searchResult.length) {
                            this.setState({ noResults: true, searchResults: [] })
                        }
                        else {
                            this.setState({
                                searchResults: result.searchResult,
                                circles: result.circles,
                                noResults: false
                            });
                        }

                    },
                    (error) => {
                        console.log(error)
                    }
                )
        }
        else {
            window.location.replace('https://92227aaf.sibforms.com/serve/MUIEAGgYidvEV8zVwsS88rALXyaWuAzyCppEnCk93a_ns252ochk9sXPkvj77aVQEwGPfMeqVky1YyjqhXEV6PSlqdYNFJcA78BCBo3Rjkw_3slf5y5GrJBLsBh1RQkWLncWYZ6UthYWsgf9JXSI6VhV0sVKQFrttv9-xJ2nlN8k-j28oC4DPdZ20zqEsZoy2V_sn0FVjGj3qyie')
        }
    }

    searchJob() {
        if (localStorage.token) {
            let searchString = (this.state.job ? this.state.job : 'null')
            fetch(process.env.REACT_APP_API_URL + "/public/job-search/" + searchString, {
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.token
                }),
            })
                .then(res => { if (res.status === 401) window.location.replace('/login'); return res.json() })
                .then(
                    (result) => {
                        if (!result.searchResult || !result.searchResult.length) {
                            this.setState({ noResults: true, searchResults: [] })
                        }
                        else {
                            this.setState({
                                searchResults: result.searchResult,
                                circles: result.circles,
                                noResults: false
                            });
                        }

                    },
                    (error) => {
                        console.log(error)
                    }
                )
        }
        else {
            window.location.replace('https://92227aaf.sibforms.com/serve/MUIEAGgYidvEV8zVwsS88rALXyaWuAzyCppEnCk93a_ns252ochk9sXPkvj77aVQEwGPfMeqVky1YyjqhXEV6PSlqdYNFJcA78BCBo3Rjkw_3slf5y5GrJBLsBh1RQkWLncWYZ6UthYWsgf9JXSI6VhV0sVKQFrttv9-xJ2nlN8k-j28oC4DPdZ20zqEsZoy2V_sn0FVjGj3qyie')
        }
    }

    addToCircle(index, id) {
        fetch(process.env.REACT_APP_API_URL + "/public/add-to-circle-s/" + id + "/" + index, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.token
            })
        })
            .then(res => { if (res.status === 401) window.location.replace('/login'); return res.json() })
            .then(res => this.setState({ circles: res.circles }))
    }

    render() {
        let thirdDegreeIndex = this.state.searchResults.findIndex(x => x.lvl == 2);
        return <>
            <div className="w-full m-auto max-w-7xl">

                <ul className="flex ml-5 space-x-px">
                    <li onClick={() => this.setState({ searchType: 'person', searchResults: [], onlyAvailable: false, category: '', job: '', name: '' })} className={`cursor-pointer hover:text-neutral-300 font-semibold px-6 py-2 uppercase tracking-wide text-white ${this.state.searchType == 'person' ? 'bg-[#F0AD4E]' : 'bg-[#2C3E50]'}`}><a >Person Search</a></li>
                    <li onClick={() => this.setState({ searchType: 'crew', searchResults: [], onlyAvailable: false, category: '', job: '', name: '' })} className={`cursor-pointer hover:text-neutral-300 font-semibold px-6 py-2 uppercase tracking-wide text-white ${this.state.searchType == 'crew' ? 'bg-[#F0AD4E]' : 'bg-[#2C3E50]'}`}><a >Crew Search</a></li>
                    <li onClick={() => this.setState({ searchType: 'job', searchResults: [], onlyAvailable: false, category: '', job: '', name: '' })} className={`cursor-pointer hover:text-neutral-300 font-semibold px-6 py-2 uppercase tracking-wide text-white ${this.state.searchType == 'job' ? 'bg-[#F0AD4E]' : 'bg-[#2C3E50]'}`} ><a  >Job Search</a></li>
                </ul>

                <div className="p-8 border-t-4 border-[#F0AD4E] bg-white/95">

                    <div className="overflow-y-auto tab-content"
                        style={{ maxHeight: '70vh' }}>
                        <div >
                            <div className="flex items-end space-x-6">
                                {this.state.searchType == "person" && <>
                                    <div className="flex-1 ">
                                        <label className="text-sm font-semibold tracking-widest uppercase text-neutral-600" style={{ display: 'block' }}>Name</label>
                                        <input className="w-full bg-white border rounded border-neutral-300" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} type="text" />
                                    </div>
                                    <div className="">
                                        <label className="text-sm font-semibold tracking-widest uppercase text-neutral-600" style={{ display: 'block' }}>Only Available</label>
                                        <input className="w-10 h-10 bg-white border rounded border-neutral-300" value={this.state.onlyAvailable} onChange={(e) => this.setState({ onlyAvailable: e.target.checked })} type="checkbox"></input>
                                    </div>
                                    <div className="">
                                        <button type="button" className="w-64 py-2 bg-[#1ABC9C] text-white font-semibold uppercase rounded text-sm tracking-wide shadow-[inset 0 -3px 0 rgba(0,0,0,0.15)]" onClick={() => this.search()}>Search</button>
                                    </div>
                                </>}
                                {this.state.searchType == "crew" && <>
                                    <div className="flex-1">
                                        <label className="text-sm font-semibold tracking-widest uppercase text-neutral-600" style={{ display: 'block' }}>Category</label>
                                        <Select className="w-full" menuPosition={'fixed'} isSearchable={true} options={this.state.categories.map(x => { return { label: x.category, value: x.category } })} value={{ value: this.state.category, label: this.state.category }} onChange={(e) => this.setState({ category: e.value })}></Select>
                                    </div>
                                    <div className="flex-1">
                                        <label className="text-sm font-semibold tracking-widest uppercase text-neutral-600" style={{ display: 'block' }}>Job</label>
                                        <Select className="w-full" menuPosition={'fixed'} isSearchable={true} options={[].concat.apply([], this.state.categories.filter(x => !this.state.category || this.state.category == x.category).map(x => x.jobs.map(x => { return { label: x, value: x } })))} value={{ label: this.state.job, value: this.state.job }} onChange={(e) => this.setState({ job: e.value })}></Select>
                                    </div>
                                    <div className="col-lg-2 col-md-1 bottommargin-sm">
                                        <label className="text-sm font-semibold tracking-widest uppercase text-neutral-600" style={{ display: 'block' }}>Only Available</label>
                                        <input className="w-10 h-10 bg-white border rounded border-neutral-300" value={this.state.onlyAvailable} onChange={(e) => this.setState({ onlyAvailable: e.target.checked })} type="checkbox"></input>
                                    </div>
                                    <div className="col-lg-2 col-md-6 col-md-12 bottommargin-sm">
                                        <button type="button" className="w-64 py-2 bg-[#1ABC9C] text-white font-semibold uppercase rounded text-sm tracking-wide shadow-[inset 0 -3px 0 rgba(0,0,0,0.15)]" onClick={() => this.search()}>Search</button>
                                    </div>
                                </>}
                                {this.state.searchType == "job" && <>
                                    <div className="flex-1">
                                        <label className="text-sm font-semibold tracking-widest uppercase text-neutral-600" style={{ display: 'block' }}>Category</label>
                                        <Select className="w-full" menuPosition={'fixed'} isSearchable={true} options={this.state.categories.map(x => { return { label: x.category, value: x.category } })} value={{ value: this.state.category, label: this.state.category }} onChange={(e) => this.setState({ category: e.value })}></Select>
                                    </div>
                                    <div className="flex-1">
                                        <label className="text-sm font-semibold tracking-widest uppercase text-neutral-600" style={{ display: 'block' }}>Job</label>
                                        <Select className="w-full" menuPosition={'fixed'} isSearchable={true} options={[].concat.apply([], this.state.categories.filter(x => !this.state.category || this.state.category == x.category).map(x => x.jobs.map(x => { return { label: x, value: x } })))} value={{ label: this.state.job, value: this.state.job }} onChange={(e) => this.setState({ job: e.value })}></Select>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-md-12 bottommargin-sm">
                                        <button type="button" className="w-64 py-2 bg-[#1ABC9C] text-white font-semibold uppercase rounded text-sm tracking-wide shadow-[inset 0 -3px 0 rgba(0,0,0,0.15)]" onClick={() => this.searchJob()}>Search</button>
                                    </div>
                                </>}
                            </div>

                            {this.state.noResults && <div>
                                <div className="card" style={{ marginBottom: '10px' }}>No Results Found. C8 searches using your connections, make sure you have at least one connection to view search results. Go to Profile &gt; Suggestions to start building your network.</div>
                            </div>}
                            {this.state.searchType != 'job' && (this.state.searchResults || true) && <>
                                {this.state.searchResults.map((x, i) => {
                                    if (x.d1.degreeOne) {
                                        return <>
                                            <h1 >First Degree</h1>
                                            {x.contacts.length == 0 && <div className="card" style={{ marginBottom: '10px', padding: '15px' }}>You have no search results in this degree.</div>}
                                            {x.contacts.length > 0 && <div className="card" style={{ marginBottom: '10px' }}>

                                                <div className="card-header">
                                                    My Connections
                                                </div>
                                                <div className="card-body-padding">
                                                    <table className="table mb-0" style={{ width: '100%' }}>
                                                        <thead>
                                                            <tr>
                                                                <th className="text-neutral-800">Name</th>
                                                                <th className="text-neutral-800">Job</th>
                                                                <th className="text-neutral-800">Circle</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {x.contacts.map(y => <tr>
                                                                <td><a href={'/profile?id=' + y._id}>{y.fields.first_name} {y.fields.middle_names} {y.fields.last_name}</a> </td>
                                                                <td>{y.fields.job}</td>
                                                                <td >{this.state.circles[0].find(z => z.user == y._id) ? `In Family (${this.state.circles[0].length}/25)` :
                                                                    this.state.circles[1].find(z => z.user == y._id) ? `In Friends (${this.state.circles[1].length}/125)` :
                                                                        this.state.circles[2].find(z => z.user == y._id) ? `In Acquaintances (${this.state.circles[2].length}/450)` : ''}

                                                                    {!this.state.circles[2].find(z => z.user == y._id) && <a onClick={() => this.addToCircle(3, y._id)} style={{ float: 'right' }} className="button button-rounded button-reveal button-small button-border text-end"><i className="icon-plus"></i><span>Acquaintances ({this.state.circles[2].length}/450)</span></a>}
                                                                    {!this.state.circles[1].find(z => z.user == y._id) && <a onClick={() => this.addToCircle(2, y._id)} style={{ float: 'right' }} className="button button-rounded button-reveal button-small button-border text-end"><i className="icon-plus"></i><span>Friends ({this.state.circles[1].length}/125)</span></a>}
                                                                    {!this.state.circles[0].find(z => z.user == y._id) && <a onClick={() => this.addToCircle(1, y._id)} style={{ float: 'right' }} className="button button-rounded button-reveal button-small button-border text-end"><i className="icon-plus"></i><span>Family ({this.state.circles[0].length}/25)</span></a>}
                                                                </td>
                                                            </tr>)}
                                                        </tbody>
                                                    </table>

                                                </div>

                                            </div>}
                                            <h1>Second Degree</h1>
                                        </>
                                    }
                                    else {
                                        return <>
                                            {thirdDegreeIndex == i && <h1>Third Degree</h1>}
                                            <div className="card" style={{ marginBottom: '10px' }}>
                                                <div className="card-header">
                                                    <a className="text-[#1ABC9C]" href={'/profile?id=' + x.d1._id}>{x.d1.fields.first_name} {x.d1.fields.middle_names} {x.d1.fields.last_name}</a> - {x.d1.fields.job}
                                                    {x.lvl == 1 && <span style={{ float: 'right' }}>
                                                        {this.state.circles[0].find(y => y.user == x.d1._id) ? `In Family (${this.state.circles[0].length}/25)` :
                                                            this.state.circles[1].find(y => y.user == x.d1._id) ? `In Friends (${this.state.circles[1].length}/125)` :
                                                                this.state.circles[2].find(y => y.user == x.d1._id) ? `In Acquaintances (${this.state.circles[2].length}/450)` : ''}
                                                    </span>}
                                                    {x.lvl == 2 && <span style={{ float: 'right' }}>Not Acquainted</span>}
                                                </div>
                                                {x.contacts.length > 0 && <div className="card-body-padding">
                                                    {x.lvl == 1 && <p className="card-text">{x.d1.fields.first_name}'s contacts that match your search term are displayed below. Get in touch with {x.d1.fields.first_name} to find out more about them.</p>}
                                                    {x.lvl == 2 && <p className="card-text">You know <a href={'/profile?id=' + x.d1._id}>{x.directContact.fields.first_name} {x.directContact.fields.middle_names} {x.directContact.fields.last_name}</a> who knows {x.d1.fields.first_name} {x.d1.fields.middle_names} {x.d1.fields.last_name}. {x.d1.fields.first_name}'s contacts that match your search term are displayed below. Get in touch with {x.d1.fields.first_name} (via {x.directContact.fields.first_name}, if need be) to find out more about them.</p>}
                                                </div>}
                                                <div className="card-body-padding">
                                                    {x.contacts.length > 0 && <table className="table mb-0" style={{ width: '100%' }}>
                                                        <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Job</th>
                                                                <th>Availability</th>
                                                                <th>Circle</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {x.contacts.map(y => <tr>
                                                                <td><a href={'/profile?id=' + y._id}>{y.fields.first_name} {y.fields.middle_names} {y.fields.last_name}</a> </td>
                                                                <td>{y.fields.job}</td>
                                                                <td>{y.fields.available_for_work ? 'Yes' : 'No'} {y.fields.available_from_date ? 'Available from ' + new Date(y.fields.available_from_date).toDateString() : ''}</td>
                                                                <td>{this.state.circles[0].find(z => z.user == y._id) ? `In Family (${this.state.circles[0].length}/25)` :
                                                                    this.state.circles[1].find(z => z.user == y._id) ? `In Friends (${this.state.circles[1].length}/125)` :
                                                                        this.state.circles[2].find(z => z.user == y._id) ? `In Acquaintances (${this.state.circles[2].length}/450)` : 'Not Acquainted'}

                                                                    {!this.state.circles[2].find(z => z.user == y._id) && <a onClick={() => this.addToCircle(3, y._id)} style={{ float: 'right' }} className="button button-rounded button-reveal button-small button-border text-end"><i className="icon-plus"></i><span>Acquaintances ({this.state.circles[2].length}/450)</span></a>}
                                                                    {!this.state.circles[1].find(z => z.user == y._id) && <a onClick={() => this.addToCircle(2, y._id)} style={{ float: 'right' }} className="button button-rounded button-reveal button-small button-border text-end"><i className="icon-plus"></i><span>Friends ({this.state.circles[1].length}/125)</span></a>}
                                                                    {!this.state.circles[0].find(z => z.user == y._id) && <a onClick={() => this.addToCircle(1, y._id)} style={{ float: 'right' }} className="button button-rounded button-reveal button-small button-border text-end"><i className="icon-plus"></i><span>Family ({this.state.circles[0].length}/25)</span></a>}

                                                                </td>
                                                            </tr>)}
                                                        </tbody>
                                                    </table>}

                                                </div>

                                            </div></>
                                    }

                                }
                                )}
                            </>}

                            {this.state.searchType == 'job' && (this.state.searchResults || true) && <>
                                {this.state.searchResults.map(x => {
                                    if (x.d1.degreeOne) {
                                        return <>
                                            <h1>First Degree (My Connections)</h1>
                                            {x.contacts.length == 0 && <div>You have no search results in this degree.</div>}
                                            {x.contacts.length > 0 && <div className="card" style={{ marginBottom: '10px' }}>
                                                <div className="card-header">

                                                </div>
                                                <div className="card-body-padding">
                                                    <table className="table mb-0" style={{ width: '100%' }}>
                                                        <thead>
                                                            <tr>
                                                                <th className="text-neutral-800">Posted By</th>
                                                                <th className="text-neutral-800">Job</th>
                                                                <th className="text-neutral-800">Details</th>
                                                                <th className="text-neutral-800">Location</th>
                                                                <th className="text-neutral-800">Closing Date</th>
                                                                <th className="text-neutral-800">Apply</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {x.contacts.map(y => <tr>
                                                                <td>{y.postedBy}</td>
                                                                <td>{y.job}</td>
                                                                <td>{y.description}</td>
                                                                <td>{y.location}</td>
                                                                <td>{new Date(y.closing_date).toDateString()}</td>
                                                                <td><span style={{ textDecoration: 'underline' }}><a href={`profile?id=${y.postedId}&apply=${y.applyId}`}>Apply</a></span></td>
                                                            </tr>)}
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>}
                                            <h1>Second Degree</h1>
                                        </>
                                    }
                                    else {
                                        return <>
                                            <div className="card" style={{ marginBottom: '10px' }}>
                                                <div className="card-header">
                                                    <a className="text-[#1ABC9C]" href={'/profile?id=' + x.d1._id}>{x.d1.fields.first_name} {x.d1.fields.middle_names} {x.d1.fields.last_name}</a> - {x.d1.fields.job}
                                                    <span style={{ float: 'right' }}>
                                                        {this.state.circles[0].find(y => y.user == x.d1._id) ? `In Family (${this.state.circles[0].length}/25)` :
                                                            this.state.circles[1].find(y => y.user == x.d1._id) ? `In Friends (${this.state.circles[1].length}/125)` :
                                                                this.state.circles[2].find(y => y.user == x.d1._id) ? `In Acquaintances (${this.state.circles[2].length}/450)` : 'Not Acquainted'}
                                                    </span>
                                                </div>
                                                {x.contacts.length > 0 && <div className="card-body-padding">
                                                    <p className="card-text">{x.d1.fields.first_name}'s contacts that have posted jobs matching your search as listed below. Get in touch with {x.d1.fields.first_name} to find out more about them.</p>
                                                </div>}
                                                <div className="card-body-padding">
                                                    {x.contacts.length > 0 && <table className="table mb-0" style={{ width: '100%' }}>
                                                        <thead>
                                                            <tr>
                                                                <th>Posted By</th>
                                                                <th>Job</th>
                                                                <th>Details</th>
                                                                <th>Location</th>
                                                                <th>Closing Date</th>
                                                                <th>Apply</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {x.contacts.map(y => <tr>
                                                                <td>{y.postedBy}</td>
                                                                <td>{y.job}</td>
                                                                <td>{y.description}</td>
                                                                <td>{y.location}</td>
                                                                <td>{new Date(y.closing_date).toDateString()}</td>
                                                                <td><span style={{ textDecoration: 'underline' }}><a href={`profile?id=${y.postedId}&apply=${y.applyId}`}>Apply</a></span></td>
                                                            </tr>)}
                                                        </tbody>
                                                    </table>}

                                                </div>

                                            </div></>
                                    }



                                }



                                )}
                            </>}
                        </div>
                    </div>

                </div>


            </div>







        </>






    }
}