import React from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';






import 'react-toastify/dist/ReactToastify.css';





export default class Profile extends React.Component {
      
    constructor(props) {
        super(props)
        this.state = { job_listings: [], job_applications: [], job_listing: {}, job_application: {}, job_listing_edit_id: null, isLoaded: false, profile: {}, id: '', selectedTab: 0, updating: false, metaData: {}, showInviteModal: false, inviteCode: '', circles: [], invitedUsers: [], categories: [], busy:false, fileName: null , file: null}
    }
    componentDidMount() {
  
        const queryParams = new URLSearchParams(window.location.search)
        const id = queryParams.get('id')
        const edit = queryParams.get('edit')
        const invite = queryParams.get('invite')
        const apply = queryParams.get('apply')
        const listing = queryParams.get('listing')
     
        if (invite) this.setState({ showInviteModal: true })
        if (edit) this.setState({ updating: true })
        if (apply) this.setState({ job_application_id: apply, adding_job_application: true })
        if (listing) this.setState({ adding_job_listing: true })
        fetch(process.env.REACT_APP_API_URL + '/public/object/' + id, {
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.token,
            }),
        })
            .then((res) => {
                if (res.status === 401) window.location.replace('/login')
                return res.json()
            })
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        profile: result.object.fields,
                        metaData: result.meta,
                        id: id,
                        inviteCode: result.inviteCode,
                        circles: result.circles,
                        invitedUsers: result.linkedUsers,
                    })
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error,
                    })
                }
            )
        fetch(process.env.REACT_APP_API_URL + '/public/job_listings/' + id, {
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.token,
            }),
        })
            .then((res) => {
                if (res.status === 401) window.location.replace('/login')
                return res.json()
            })
            .then(
                (result) => {
                    console.log(result)
                    this.setState({
                        job_listings: result.job_listings,
                    })
                },
                (error) => {
                    this.setState({
                        error,
                    })
                }
            )

        fetch(process.env.REACT_APP_API_URL + '/public/job_applications', {
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.token,
            }),
        })
            .then((res) => {
                if (res.status === 401) window.location.replace('/login')
                return res.json()
            })
            .then(
                (result) => {
                    console.log(result)
                    this.setState({
                        job_applications: result.job_applications,
                    })
                },
                (error) => {
                    this.setState({
                        error,
                    })
                }
            )

        fetch(process.env.REACT_APP_API_URL + '/jobs')
            .then((res) => {
                if (res.status === 401) window.location.replace('/login')
                return res.json()
            })
            .then(
                (result) => {
                    this.setState({
                        categories: result,
                    })
                },
                (error) => {
                    console.log(error)
                }
            )
    }
    
    saveProfile() {
        fetch(process.env.REACT_APP_API_URL + '/public/profile', {
            method: 'POST',
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.token,
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify({ profile: this.state.profile }),
        }).then((res) => this.setState({ updating: false }))
    }

    saveJobListing() {
        fetch(process.env.REACT_APP_API_URL + '/public/job-listing/' + (this.state.job_listing_edit_id ? this.state.job_listing_edit_id : 'new'), {
            method: 'POST',
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.token,
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify({ job_listing: this.state.job_listing }),
        }).then((res) => {
            fetch(process.env.REACT_APP_API_URL + '/public/job_listings/own', {
                headers: new Headers({
                    Authorization: 'Bearer ' + localStorage.token,
                }),
            })
                .then((res) => {
                    if (res.status === 401) window.location.replace('/login')
                    return res.json()
                })
                .then(
                    (result) => {
                        this.setState({
                            job_listings: result.job_listings,
                        })
                    },
                    (error) => {
                        this.setState({
                            error,
                        })
                    }
                )
            this.setState({ adding_job_listing: false })
        })
    }

    deleteJobListing(id) {
        fetch(process.env.REACT_APP_API_URL + '/public/job-listing/' + id, {
            method: 'DELETE',
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.token,
            }),
        }).then((res) => {
            fetch(process.env.REACT_APP_API_URL + '/public/job_listings/own', {
                headers: new Headers({
                    Authorization: 'Bearer ' + localStorage.token,
                }),
            })
                .then((res) => {
                    if (res.status === 401) window.location.replace('/login')
                    return res.json()
                })
                .then(
                    (result) => {
                        this.setState({
                            job_listings: result.job_listings,
                        })
                    },
                    (error) => {
                        this.setState({
                            error,
                        })
                    }
                )
            this.setState({ adding_job_listing: false })
        })
    }

    saveJobApplication() {
        fetch(process.env.REACT_APP_API_URL + '/public/job-application/' + this.state.job_application_id, {
            method: 'POST',
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.token,
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify({ job_application: this.state.job_application }),
        }).then((res) => {
            this.setState({ adding_job_application: false, job_application: {}, job_application_id: null })
        })
    }

    handleFileInputChange = (event) => {
        const file = event.target.files[0]
        console.log('file', file)
        if ((file && file.type === 'text/csv') || file.type === 'application/vnd.ms-excel') {
           this.setState({ fileName: file.name })
           this.setState({ file: file })
        }
        else{
            toast.error('only csv files are supported', {
                position: 'top-right',
            })
        }
    }

    uploadFile() {
        // Create a new FormData object to send the file
        this.setState({busy: true})
        const formData = new FormData()
        formData.append('file', this.state.file)
        console.log(formData)
        fetch(process.env.REACT_APP_API_URL + '/admin/register-bulk-user', {
            method: 'POST',
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.token,
                // 'Content-Type': 'application/json',
            }),
            body: formData,
        })
            .then((res) => {
                if (res.ok) {
                    return res.json()
                } else {
                    console.log(res)
                    throw new Error(res.results)
                }
            })
            .then((data) => {
                // Handle the response data
                console.log(data)
                // Check for errors and display them
                const errorMessages = data.results.filter((result) => result.status.startsWith('Error:'))
                const successMessages = data.results.filter((result) => result.status.startsWith('R'))
                console.log(successMessages)
                console.log(errorMessages)
                if (errorMessages.length > 0) {
                    // Display error messages to the user
                    errorMessages.forEach((error) => {
                        toast.error(`${error.status}`, {
                            position: 'top-right',
                        })
              
                    })
                } 
                if (successMessages.length > 0) {
               toast.success(`${successMessages.length } User Registered successfully`, {
                   position: 'top-right',
               })
                }
            })
            .catch((error) => {
                console.log(error)
                 toast.error('File upload failed', {
                     position: 'top-right',
                 })
                // alert('File upload failed')
            })
               this.setState({ busy: false })
               this.setState({ uploaded: false })
    }

    renderEditModal() {
        if (!this.state.updating) return <></>
        return (
            <>
                <div onClick={() => this.setState({ updating: false })} style={{ position: 'fixed', width: '100vw', height: '100vh', background: 'rgba(0,0,0,0.5)', zIndex: '100', top: '0', left: '0' }}></div>
                <div style={{ position: 'fixed', width: '100vw', height: '100vh', zIndex: '100', top: '0', left: '0' }}>
                    <div style={{ position: 'relative', maxWidth: '80vw', zIndex: '102', top: '100px', margin: 'auto' }}>
                        <div className='mx-auto border-0 card' style={{ maxHeight: '80vh', overflowY: 'scroll' }}>
                            <div className='p-4 card-body'>
                                <h3 className='card-title font-body'>Update your details.</h3>
                                <div className='form-widget'>
                                    <div className='form-result'></div>

                                    <div className='mb-0 position-relative'>
                                        <div className='form-process'>
                                            <div className='css3-spinner'>
                                                <div className='css3-spinner-scaler'></div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 form-group'>
                                                <label>Job:</label>
                                                <Select
                                                    isMulti={true}
                                                    options={[].concat.apply(
                                                        [],
                                                        this.state.categories.map((x) =>
                                                            x.jobs.map((x) => {
                                                                return { label: x, value: x }
                                                            })
                                                        )
                                                    )}
                                                    value={
                                                        this.state.profile.job
                                                            ? this.state.profile.job.split(',').map((x) => {
                                                                  return { label: x, value: x }
                                                              })
                                                            : []
                                                    }
                                                    onChange={(e) => {
                                                        this.setState({ profile: { ...this.state.profile, job: e.map((x) => x.value).join(', ') } })
                                                    }}
                                                ></Select>
                                                {/* <select multiple={true} value={this.state.profile.job.split(',')} onChange={(e) => this.setState({ profile: { ...this.state.profile, job: e.target.value } })} className="form-select">
                                                <option value=""></option>
                                                {this.state.categories && this.state.categories.map(c => <optgroup label={c.category}>
                                                    {c.jobs.map(x => <option value={x}>{x}</option>)}
                                                </optgroup>
                                                )}
                                            </select> */}
                                            </div>
                                            <div className='col-4 form-group'>
                                                <label>First Name:</label>
                                                <input value={this.state.profile.first_name} onChange={(e) => this.setState({ profile: { ...this.state.profile, first_name: e.target.value } })} type='text' className='form-control' placeholder='First Name...' />
                                            </div>

                                            <div className='col-4 form-group'>
                                                <label>Middles Names:</label>
                                                <input value={this.state.profile.middle_names} onChange={(e) => this.setState({ profile: { ...this.state.profile, middle_names: e.target.value } })} type='text' className='form-control' placeholder='Middles Names...' />
                                            </div>

                                            <div className='col-4 form-group'>
                                                <label>Last Name:</label>
                                                <input value={this.state.profile.last_name} onChange={(e) => this.setState({ profile: { ...this.state.profile, last_name: e.target.value } })} type='text' className='form-control' placeholder='Last Name...' />
                                            </div>

                                            <div className='col-12 form-group'>
                                                <label>Bio:</label>
                                                <textarea rows='3' value={this.state.profile.bio} onChange={(e) => this.setState({ profile: { ...this.state.profile, bio: e.target.value } })} type='text' className='form-control' placeholder='Bio...' />
                                            </div>
                                            <div className='col-2 form-group'>
                                                <label>Country Code:</label>
                                                <select value={this.state.profile.phone_country_code} onChange={(e) => this.setState({ profile: { ...this.state.profile, phone_country_code: e.target.value } })} className='form-select'>
                                                    <option data-countryCode='GB' value='44' Selected>
                                                        UK (+44)
                                                    </option>
                                                    <option data-countryCode='US' value='1'>
                                                        USA (+1)
                                                    </option>
                                                    <optgroup label='Other countries'>
                                                        <option data-countryCode='DZ' value='213'>
                                                            Algeria (+213)
                                                        </option>
                                                        <option data-countryCode='AD' value='376'>
                                                            Andorra (+376)
                                                        </option>
                                                        <option data-countryCode='AO' value='244'>
                                                            Angola (+244)
                                                        </option>
                                                        <option data-countryCode='AI' value='1264'>
                                                            Anguilla (+1264)
                                                        </option>
                                                        <option data-countryCode='AG' value='1268'>
                                                            Antigua &amp; Barbuda (+1268)
                                                        </option>
                                                        <option data-countryCode='AR' value='54'>
                                                            Argentina (+54)
                                                        </option>
                                                        <option data-countryCode='AM' value='374'>
                                                            Armenia (+374)
                                                        </option>
                                                        <option data-countryCode='AW' value='297'>
                                                            Aruba (+297)
                                                        </option>
                                                        <option data-countryCode='AU' value='61'>
                                                            Australia (+61)
                                                        </option>
                                                        <option data-countryCode='AT' value='43'>
                                                            Austria (+43)
                                                        </option>
                                                        <option data-countryCode='AZ' value='994'>
                                                            Azerbaijan (+994)
                                                        </option>
                                                        <option data-countryCode='BS' value='1242'>
                                                            Bahamas (+1242)
                                                        </option>
                                                        <option data-countryCode='BH' value='973'>
                                                            Bahrain (+973)
                                                        </option>
                                                        <option data-countryCode='BD' value='880'>
                                                            Bangladesh (+880)
                                                        </option>
                                                        <option data-countryCode='BB' value='1246'>
                                                            Barbados (+1246)
                                                        </option>
                                                        <option data-countryCode='BY' value='375'>
                                                            Belarus (+375)
                                                        </option>
                                                        <option data-countryCode='BE' value='32'>
                                                            Belgium (+32)
                                                        </option>
                                                        <option data-countryCode='BZ' value='501'>
                                                            Belize (+501)
                                                        </option>
                                                        <option data-countryCode='BJ' value='229'>
                                                            Benin (+229)
                                                        </option>
                                                        <option data-countryCode='BM' value='1441'>
                                                            Bermuda (+1441)
                                                        </option>
                                                        <option data-countryCode='BT' value='975'>
                                                            Bhutan (+975)
                                                        </option>
                                                        <option data-countryCode='BO' value='591'>
                                                            Bolivia (+591)
                                                        </option>
                                                        <option data-countryCode='BA' value='387'>
                                                            Bosnia Herzegovina (+387)
                                                        </option>
                                                        <option data-countryCode='BW' value='267'>
                                                            Botswana (+267)
                                                        </option>
                                                        <option data-countryCode='BR' value='55'>
                                                            Brazil (+55)
                                                        </option>
                                                        <option data-countryCode='BN' value='673'>
                                                            Brunei (+673)
                                                        </option>
                                                        <option data-countryCode='BG' value='359'>
                                                            Bulgaria (+359)
                                                        </option>
                                                        <option data-countryCode='BF' value='226'>
                                                            Burkina Faso (+226)
                                                        </option>
                                                        <option data-countryCode='BI' value='257'>
                                                            Burundi (+257)
                                                        </option>
                                                        <option data-countryCode='KH' value='855'>
                                                            Cambodia (+855)
                                                        </option>
                                                        <option data-countryCode='CM' value='237'>
                                                            Cameroon (+237)
                                                        </option>
                                                        <option data-countryCode='CA' value='1'>
                                                            Canada (+1)
                                                        </option>
                                                        <option data-countryCode='CV' value='238'>
                                                            Cape Verde Islands (+238)
                                                        </option>
                                                        <option data-countryCode='KY' value='1345'>
                                                            Cayman Islands (+1345)
                                                        </option>
                                                        <option data-countryCode='CF' value='236'>
                                                            Central African Republic (+236)
                                                        </option>
                                                        <option data-countryCode='CL' value='56'>
                                                            Chile (+56)
                                                        </option>
                                                        <option data-countryCode='CN' value='86'>
                                                            China (+86)
                                                        </option>
                                                        <option data-countryCode='CO' value='57'>
                                                            Colombia (+57)
                                                        </option>
                                                        <option data-countryCode='KM' value='269'>
                                                            Comoros (+269)
                                                        </option>
                                                        <option data-countryCode='CG' value='242'>
                                                            Congo (+242)
                                                        </option>
                                                        <option data-countryCode='CK' value='682'>
                                                            Cook Islands (+682)
                                                        </option>
                                                        <option data-countryCode='CR' value='506'>
                                                            Costa Rica (+506)
                                                        </option>
                                                        <option data-countryCode='HR' value='385'>
                                                            Croatia (+385)
                                                        </option>
                                                        <option data-countryCode='CU' value='53'>
                                                            Cuba (+53)
                                                        </option>
                                                        <option data-countryCode='CY' value='90392'>
                                                            Cyprus North (+90392)
                                                        </option>
                                                        <option data-countryCode='CY' value='357'>
                                                            Cyprus South (+357)
                                                        </option>
                                                        <option data-countryCode='CZ' value='42'>
                                                            Czech Republic (+42)
                                                        </option>
                                                        <option data-countryCode='DK' value='45'>
                                                            Denmark (+45)
                                                        </option>
                                                        <option data-countryCode='DJ' value='253'>
                                                            Djibouti (+253)
                                                        </option>
                                                        <option data-countryCode='DM' value='1809'>
                                                            Dominica (+1809)
                                                        </option>
                                                        <option data-countryCode='DO' value='1809'>
                                                            Dominican Republic (+1809)
                                                        </option>
                                                        <option data-countryCode='EC' value='593'>
                                                            Ecuador (+593)
                                                        </option>
                                                        <option data-countryCode='EG' value='20'>
                                                            Egypt (+20)
                                                        </option>
                                                        <option data-countryCode='SV' value='503'>
                                                            El Salvador (+503)
                                                        </option>
                                                        <option data-countryCode='GQ' value='240'>
                                                            Equatorial Guinea (+240)
                                                        </option>
                                                        <option data-countryCode='ER' value='291'>
                                                            Eritrea (+291)
                                                        </option>
                                                        <option data-countryCode='EE' value='372'>
                                                            Estonia (+372)
                                                        </option>
                                                        <option data-countryCode='ET' value='251'>
                                                            Ethiopia (+251)
                                                        </option>
                                                        <option data-countryCode='FK' value='500'>
                                                            Falkland Islands (+500)
                                                        </option>
                                                        <option data-countryCode='FO' value='298'>
                                                            Faroe Islands (+298)
                                                        </option>
                                                        <option data-countryCode='FJ' value='679'>
                                                            Fiji (+679)
                                                        </option>
                                                        <option data-countryCode='FI' value='358'>
                                                            Finland (+358)
                                                        </option>
                                                        <option data-countryCode='FR' value='33'>
                                                            France (+33)
                                                        </option>
                                                        <option data-countryCode='GF' value='594'>
                                                            French Guiana (+594)
                                                        </option>
                                                        <option data-countryCode='PF' value='689'>
                                                            French Polynesia (+689)
                                                        </option>
                                                        <option data-countryCode='GA' value='241'>
                                                            Gabon (+241)
                                                        </option>
                                                        <option data-countryCode='GM' value='220'>
                                                            Gambia (+220)
                                                        </option>
                                                        <option data-countryCode='GE' value='7880'>
                                                            Georgia (+7880)
                                                        </option>
                                                        <option data-countryCode='DE' value='49'>
                                                            Germany (+49)
                                                        </option>
                                                        <option data-countryCode='GH' value='233'>
                                                            Ghana (+233)
                                                        </option>
                                                        <option data-countryCode='GI' value='350'>
                                                            Gibraltar (+350)
                                                        </option>
                                                        <option data-countryCode='GR' value='30'>
                                                            Greece (+30)
                                                        </option>
                                                        <option data-countryCode='GL' value='299'>
                                                            Greenland (+299)
                                                        </option>
                                                        <option data-countryCode='GD' value='1473'>
                                                            Grenada (+1473)
                                                        </option>
                                                        <option data-countryCode='GP' value='590'>
                                                            Guadeloupe (+590)
                                                        </option>
                                                        <option data-countryCode='GU' value='671'>
                                                            Guam (+671)
                                                        </option>
                                                        <option data-countryCode='GT' value='502'>
                                                            Guatemala (+502)
                                                        </option>
                                                        <option data-countryCode='GN' value='224'>
                                                            Guinea (+224)
                                                        </option>
                                                        <option data-countryCode='GW' value='245'>
                                                            Guinea - Bissau (+245)
                                                        </option>
                                                        <option data-countryCode='GY' value='592'>
                                                            Guyana (+592)
                                                        </option>
                                                        <option data-countryCode='HT' value='509'>
                                                            Haiti (+509)
                                                        </option>
                                                        <option data-countryCode='HN' value='504'>
                                                            Honduras (+504)
                                                        </option>
                                                        <option data-countryCode='HK' value='852'>
                                                            Hong Kong (+852)
                                                        </option>
                                                        <option data-countryCode='HU' value='36'>
                                                            Hungary (+36)
                                                        </option>
                                                        <option data-countryCode='IS' value='354'>
                                                            Iceland (+354)
                                                        </option>
                                                        <option data-countryCode='IN' value='91'>
                                                            India (+91)
                                                        </option>
                                                        <option data-countryCode='ID' value='62'>
                                                            Indonesia (+62)
                                                        </option>
                                                        <option data-countryCode='IR' value='98'>
                                                            Iran (+98)
                                                        </option>
                                                        <option data-countryCode='IQ' value='964'>
                                                            Iraq (+964)
                                                        </option>
                                                        <option data-countryCode='IE' value='353'>
                                                            Ireland (+353)
                                                        </option>
                                                        <option data-countryCode='IL' value='972'>
                                                            Israel (+972)
                                                        </option>
                                                        <option data-countryCode='IT' value='39'>
                                                            Italy (+39)
                                                        </option>
                                                        <option data-countryCode='JM' value='1876'>
                                                            Jamaica (+1876)
                                                        </option>
                                                        <option data-countryCode='JP' value='81'>
                                                            Japan (+81)
                                                        </option>
                                                        <option data-countryCode='JO' value='962'>
                                                            Jordan (+962)
                                                        </option>
                                                        <option data-countryCode='KZ' value='7'>
                                                            Kazakhstan (+7)
                                                        </option>
                                                        <option data-countryCode='KE' value='254'>
                                                            Kenya (+254)
                                                        </option>
                                                        <option data-countryCode='KI' value='686'>
                                                            Kiribati (+686)
                                                        </option>
                                                        <option data-countryCode='KP' value='850'>
                                                            Korea North (+850)
                                                        </option>
                                                        <option data-countryCode='KR' value='82'>
                                                            Korea South (+82)
                                                        </option>
                                                        <option data-countryCode='KW' value='965'>
                                                            Kuwait (+965)
                                                        </option>
                                                        <option data-countryCode='KG' value='996'>
                                                            Kyrgyzstan (+996)
                                                        </option>
                                                        <option data-countryCode='LA' value='856'>
                                                            Laos (+856)
                                                        </option>
                                                        <option data-countryCode='LV' value='371'>
                                                            Latvia (+371)
                                                        </option>
                                                        <option data-countryCode='LB' value='961'>
                                                            Lebanon (+961)
                                                        </option>
                                                        <option data-countryCode='LS' value='266'>
                                                            Lesotho (+266)
                                                        </option>
                                                        <option data-countryCode='LR' value='231'>
                                                            Liberia (+231)
                                                        </option>
                                                        <option data-countryCode='LY' value='218'>
                                                            Libya (+218)
                                                        </option>
                                                        <option data-countryCode='LI' value='417'>
                                                            Liechtenstein (+417)
                                                        </option>
                                                        <option data-countryCode='LT' value='370'>
                                                            Lithuania (+370)
                                                        </option>
                                                        <option data-countryCode='LU' value='352'>
                                                            Luxembourg (+352)
                                                        </option>
                                                        <option data-countryCode='MO' value='853'>
                                                            Macao (+853)
                                                        </option>
                                                        <option data-countryCode='MK' value='389'>
                                                            Macedonia (+389)
                                                        </option>
                                                        <option data-countryCode='MG' value='261'>
                                                            Madagascar (+261)
                                                        </option>
                                                        <option data-countryCode='MW' value='265'>
                                                            Malawi (+265)
                                                        </option>
                                                        <option data-countryCode='MY' value='60'>
                                                            Malaysia (+60)
                                                        </option>
                                                        <option data-countryCode='MV' value='960'>
                                                            Maldives (+960)
                                                        </option>
                                                        <option data-countryCode='ML' value='223'>
                                                            Mali (+223)
                                                        </option>
                                                        <option data-countryCode='MT' value='356'>
                                                            Malta (+356)
                                                        </option>
                                                        <option data-countryCode='MH' value='692'>
                                                            Marshall Islands (+692)
                                                        </option>
                                                        <option data-countryCode='MQ' value='596'>
                                                            Martinique (+596)
                                                        </option>
                                                        <option data-countryCode='MR' value='222'>
                                                            Mauritania (+222)
                                                        </option>
                                                        <option data-countryCode='YT' value='269'>
                                                            Mayotte (+269)
                                                        </option>
                                                        <option data-countryCode='MX' value='52'>
                                                            Mexico (+52)
                                                        </option>
                                                        <option data-countryCode='FM' value='691'>
                                                            Micronesia (+691)
                                                        </option>
                                                        <option data-countryCode='MD' value='373'>
                                                            Moldova (+373)
                                                        </option>
                                                        <option data-countryCode='MC' value='377'>
                                                            Monaco (+377)
                                                        </option>
                                                        <option data-countryCode='MN' value='976'>
                                                            Mongolia (+976)
                                                        </option>
                                                        <option data-countryCode='MS' value='1664'>
                                                            Montserrat (+1664)
                                                        </option>
                                                        <option data-countryCode='MA' value='212'>
                                                            Morocco (+212)
                                                        </option>
                                                        <option data-countryCode='MZ' value='258'>
                                                            Mozambique (+258)
                                                        </option>
                                                        <option data-countryCode='MN' value='95'>
                                                            Myanmar (+95)
                                                        </option>
                                                        <option data-countryCode='NA' value='264'>
                                                            Namibia (+264)
                                                        </option>
                                                        <option data-countryCode='NR' value='674'>
                                                            Nauru (+674)
                                                        </option>
                                                        <option data-countryCode='NP' value='977'>
                                                            Nepal (+977)
                                                        </option>
                                                        <option data-countryCode='NL' value='31'>
                                                            Netherlands (+31)
                                                        </option>
                                                        <option data-countryCode='NC' value='687'>
                                                            New Caledonia (+687)
                                                        </option>
                                                        <option data-countryCode='NZ' value='64'>
                                                            New Zealand (+64)
                                                        </option>
                                                        <option data-countryCode='NI' value='505'>
                                                            Nicaragua (+505)
                                                        </option>
                                                        <option data-countryCode='NE' value='227'>
                                                            Niger (+227)
                                                        </option>
                                                        <option data-countryCode='NG' value='234'>
                                                            Nigeria (+234)
                                                        </option>
                                                        <option data-countryCode='NU' value='683'>
                                                            Niue (+683)
                                                        </option>
                                                        <option data-countryCode='NF' value='672'>
                                                            Norfolk Islands (+672)
                                                        </option>
                                                        <option data-countryCode='NP' value='670'>
                                                            Northern Marianas (+670)
                                                        </option>
                                                        <option data-countryCode='NO' value='47'>
                                                            Norway (+47)
                                                        </option>
                                                        <option data-countryCode='OM' value='968'>
                                                            Oman (+968)
                                                        </option>
                                                        <option data-countryCode='PW' value='680'>
                                                            Palau (+680)
                                                        </option>
                                                        <option data-countryCode='PA' value='507'>
                                                            Panama (+507)
                                                        </option>
                                                        <option data-countryCode='PG' value='675'>
                                                            Papua New Guinea (+675)
                                                        </option>
                                                        <option data-countryCode='PY' value='595'>
                                                            Paraguay (+595)
                                                        </option>
                                                        <option data-countryCode='PE' value='51'>
                                                            Peru (+51)
                                                        </option>
                                                        <option data-countryCode='PH' value='63'>
                                                            Philippines (+63)
                                                        </option>
                                                        <option data-countryCode='PL' value='48'>
                                                            Poland (+48)
                                                        </option>
                                                        <option data-countryCode='PT' value='351'>
                                                            Portugal (+351)
                                                        </option>
                                                        <option data-countryCode='PR' value='1787'>
                                                            Puerto Rico (+1787)
                                                        </option>
                                                        <option data-countryCode='QA' value='974'>
                                                            Qatar (+974)
                                                        </option>
                                                        <option data-countryCode='RE' value='262'>
                                                            Reunion (+262)
                                                        </option>
                                                        <option data-countryCode='RO' value='40'>
                                                            Romania (+40)
                                                        </option>
                                                        <option data-countryCode='RU' value='7'>
                                                            Russia (+7)
                                                        </option>
                                                        <option data-countryCode='RW' value='250'>
                                                            Rwanda (+250)
                                                        </option>
                                                        <option data-countryCode='SM' value='378'>
                                                            San Marino (+378)
                                                        </option>
                                                        <option data-countryCode='ST' value='239'>
                                                            Sao Tome &amp; Principe (+239)
                                                        </option>
                                                        <option data-countryCode='SA' value='966'>
                                                            Saudi Arabia (+966)
                                                        </option>
                                                        <option data-countryCode='SN' value='221'>
                                                            Senegal (+221)
                                                        </option>
                                                        <option data-countryCode='CS' value='381'>
                                                            Serbia (+381)
                                                        </option>
                                                        <option data-countryCode='SC' value='248'>
                                                            Seychelles (+248)
                                                        </option>
                                                        <option data-countryCode='SL' value='232'>
                                                            Sierra Leone (+232)
                                                        </option>
                                                        <option data-countryCode='SG' value='65'>
                                                            Singapore (+65)
                                                        </option>
                                                        <option data-countryCode='SK' value='421'>
                                                            Slovak Republic (+421)
                                                        </option>
                                                        <option data-countryCode='SI' value='386'>
                                                            Slovenia (+386)
                                                        </option>
                                                        <option data-countryCode='SB' value='677'>
                                                            Solomon Islands (+677)
                                                        </option>
                                                        <option data-countryCode='SO' value='252'>
                                                            Somalia (+252)
                                                        </option>
                                                        <option data-countryCode='ZA' value='27'>
                                                            South Africa (+27)
                                                        </option>
                                                        <option data-countryCode='ES' value='34'>
                                                            Spain (+34)
                                                        </option>
                                                        <option data-countryCode='LK' value='94'>
                                                            Sri Lanka (+94)
                                                        </option>
                                                        <option data-countryCode='SH' value='290'>
                                                            St. Helena (+290)
                                                        </option>
                                                        <option data-countryCode='KN' value='1869'>
                                                            St. Kitts (+1869)
                                                        </option>
                                                        <option data-countryCode='SC' value='1758'>
                                                            St. Lucia (+1758)
                                                        </option>
                                                        <option data-countryCode='SD' value='249'>
                                                            Sudan (+249)
                                                        </option>
                                                        <option data-countryCode='SR' value='597'>
                                                            Suriname (+597)
                                                        </option>
                                                        <option data-countryCode='SZ' value='268'>
                                                            Swaziland (+268)
                                                        </option>
                                                        <option data-countryCode='SE' value='46'>
                                                            Sweden (+46)
                                                        </option>
                                                        <option data-countryCode='CH' value='41'>
                                                            Switzerland (+41)
                                                        </option>
                                                        <option data-countryCode='SI' value='963'>
                                                            Syria (+963)
                                                        </option>
                                                        <option data-countryCode='TW' value='886'>
                                                            Taiwan (+886)
                                                        </option>
                                                        <option data-countryCode='TJ' value='7'>
                                                            Tajikstan (+7)
                                                        </option>
                                                        <option data-countryCode='TH' value='66'>
                                                            Thailand (+66)
                                                        </option>
                                                        <option data-countryCode='TG' value='228'>
                                                            Togo (+228)
                                                        </option>
                                                        <option data-countryCode='TO' value='676'>
                                                            Tonga (+676)
                                                        </option>
                                                        <option data-countryCode='TT' value='1868'>
                                                            Trinidad &amp; Tobago (+1868)
                                                        </option>
                                                        <option data-countryCode='TN' value='216'>
                                                            Tunisia (+216)
                                                        </option>
                                                        <option data-countryCode='TR' value='90'>
                                                            Turkey (+90)
                                                        </option>
                                                        <option data-countryCode='TM' value='7'>
                                                            Turkmenistan (+7)
                                                        </option>
                                                        <option data-countryCode='TM' value='993'>
                                                            Turkmenistan (+993)
                                                        </option>
                                                        <option data-countryCode='TC' value='1649'>
                                                            Turks &amp; Caicos Islands (+1649)
                                                        </option>
                                                        <option data-countryCode='TV' value='688'>
                                                            Tuvalu (+688)
                                                        </option>
                                                        <option data-countryCode='UG' value='256'>
                                                            Uganda (+256)
                                                        </option>
                                                        <option data-countryCode='UA' value='380'>
                                                            Ukraine (+380)
                                                        </option>
                                                        <option data-countryCode='AE' value='971'>
                                                            United Arab Emirates (+971)
                                                        </option>
                                                        <option data-countryCode='UY' value='598'>
                                                            Uruguay (+598)
                                                        </option>
                                                        <option data-countryCode='UZ' value='7'>
                                                            Uzbekistan (+7)
                                                        </option>
                                                        <option data-countryCode='VU' value='678'>
                                                            Vanuatu (+678)
                                                        </option>
                                                        <option data-countryCode='VA' value='379'>
                                                            Vatican City (+379)
                                                        </option>
                                                        <option data-countryCode='VE' value='58'>
                                                            Venezuela (+58)
                                                        </option>
                                                        <option data-countryCode='VN' value='84'>
                                                            Vietnam (+84)
                                                        </option>
                                                        <option data-countryCode='VG' value='84'>
                                                            Virgin Islands - British (+1284)
                                                        </option>
                                                        <option data-countryCode='VI' value='84'>
                                                            Virgin Islands - US (+1340)
                                                        </option>
                                                        <option data-countryCode='WF' value='681'>
                                                            Wallis &amp; Futuna (+681)
                                                        </option>
                                                        <option data-countryCode='YE' value='969'>
                                                            Yemen (North)(+969)
                                                        </option>
                                                        <option data-countryCode='YE' value='967'>
                                                            Yemen (South)(+967)
                                                        </option>
                                                        <option data-countryCode='ZM' value='260'>
                                                            Zambia (+260)
                                                        </option>
                                                        <option data-countryCode='ZW' value='263'>
                                                            Zimbabwe (+263)
                                                        </option>
                                                    </optgroup>
                                                </select>
                                            </div>

                                            <div className='col-2 form-group'>
                                                <label>Phone Number:</label>
                                                <input value={this.state.profile.phone_number} onChange={(e) => this.setState({ profile: { ...this.state.profile, phone_number: e.target.value } })} type='tel' className='form-control' placeholder='' />
                                            </div>

                                            <div className='col-2 form-group'>
                                                <label>Hide Phone Number:</label>
                                                <br />
                                                <input checked={this.state.profile.phone_number_private} onChange={(e) => this.setState({ profile: { ...this.state.profile, phone_number_private: e.target.checked } })} type='checkbox' />
                                            </div>

                                            <div className='col-3 form-group'>
                                                <label>Available For Work:</label>
                                                <br />
                                                <input checked={this.state.profile.available_for_work} onChange={(e) => this.setState({ profile: { ...this.state.profile, available_for_work: e.target.checked } })} type='checkbox' />
                                            </div>

                                            <div className='col-3 form-group'>
                                                <label>Available From:</label>
                                                <input value={this.state.profile.available_from_date} onChange={(e) => this.setState({ profile: { ...this.state.profile, available_from_date: e.target.value } })} type='date' className='form-control' />
                                            </div>

                                            <div className='col-12 form-group'>
                                                <label>Address:</label>
                                                <textarea rows='3' value={this.state.profile.address} onChange={(e) => this.setState({ profile: { ...this.state.profile, address: e.target.value } })} type='text' className='form-control' placeholder='Address...' />
                                            </div>

                                            <div className='col-6 form-group'>
                                                <label>IMDB:</label>
                                                <input value={this.state.profile.imdb_url} onChange={(e) => this.setState({ profile: { ...this.state.profile, imdb_url: e.target.value } })} type='url' className='form-control' placeholder='IMDB URL...' />
                                            </div>
                                            <div className='col-6 form-group'>
                                                <label>Twitter:</label>
                                                <input value={this.state.profile.twitter_url} onChange={(e) => this.setState({ profile: { ...this.state.profile, twitter_url: e.target.value } })} type='url' className='form-control' placeholder='Twitter URL...' />
                                            </div>
                                            <div className='col-6 form-group'>
                                                <label>LinkedIn:</label>
                                                <input value={this.state.profile.linkedin_url} onChange={(e) => this.setState({ profile: { ...this.state.profile, linkedin_url: e.target.value } })} type='url' className='form-control' placeholder='LinkedIn URL...' />
                                            </div>
                                            <div className='col-6 form-group'>
                                                <label>Website:</label>
                                                <input value={this.state.profile.website_url} onChange={(e) => this.setState({ profile: { ...this.state.profile, website_url: e.target.value } })} type='url' className='form-control' placeholder='Website URL...' />
                                            </div>

                                            <div className='col-12 form-group'>
                                                <label>Work Location:</label>
                                                <input value={this.state.profile.location} onChange={(e) => this.setState({ profile: { ...this.state.profile, location: e.target.value } })} type='tel' className='form-control' />
                                            </div>

                                            <div className='col-3 form-group'>
                                                <label>Agent Name:</label>
                                                <input value={this.state.profile.agent_name} onChange={(e) => this.setState({ profile: { ...this.state.profile, agent_name: e.target.value } })} type='text' className='form-control' placeholder='Agent Name...' />
                                            </div>

                                            <div className='col-3 form-group'>
                                                <label>Agent Country Code:</label>
                                                <select value={this.state.profile.agent_phone_country_code} onChange={(e) => this.setState({ profile: { ...this.state.profile, agent_phone_country_code: e.target.value } })} className='form-select'>
                                                    <option data-countryCode='GB' value='44' Selected>
                                                        UK (+44)
                                                    </option>
                                                    <option data-countryCode='US' value='1'>
                                                        USA (+1)
                                                    </option>
                                                    <optgroup label='Other countries'>
                                                        <option data-countryCode='DZ' value='213'>
                                                            Algeria (+213)
                                                        </option>
                                                        <option data-countryCode='AD' value='376'>
                                                            Andorra (+376)
                                                        </option>
                                                        <option data-countryCode='AO' value='244'>
                                                            Angola (+244)
                                                        </option>
                                                        <option data-countryCode='AI' value='1264'>
                                                            Anguilla (+1264)
                                                        </option>
                                                        <option data-countryCode='AG' value='1268'>
                                                            Antigua &amp; Barbuda (+1268)
                                                        </option>
                                                        <option data-countryCode='AR' value='54'>
                                                            Argentina (+54)
                                                        </option>
                                                        <option data-countryCode='AM' value='374'>
                                                            Armenia (+374)
                                                        </option>
                                                        <option data-countryCode='AW' value='297'>
                                                            Aruba (+297)
                                                        </option>
                                                        <option data-countryCode='AU' value='61'>
                                                            Australia (+61)
                                                        </option>
                                                        <option data-countryCode='AT' value='43'>
                                                            Austria (+43)
                                                        </option>
                                                        <option data-countryCode='AZ' value='994'>
                                                            Azerbaijan (+994)
                                                        </option>
                                                        <option data-countryCode='BS' value='1242'>
                                                            Bahamas (+1242)
                                                        </option>
                                                        <option data-countryCode='BH' value='973'>
                                                            Bahrain (+973)
                                                        </option>
                                                        <option data-countryCode='BD' value='880'>
                                                            Bangladesh (+880)
                                                        </option>
                                                        <option data-countryCode='BB' value='1246'>
                                                            Barbados (+1246)
                                                        </option>
                                                        <option data-countryCode='BY' value='375'>
                                                            Belarus (+375)
                                                        </option>
                                                        <option data-countryCode='BE' value='32'>
                                                            Belgium (+32)
                                                        </option>
                                                        <option data-countryCode='BZ' value='501'>
                                                            Belize (+501)
                                                        </option>
                                                        <option data-countryCode='BJ' value='229'>
                                                            Benin (+229)
                                                        </option>
                                                        <option data-countryCode='BM' value='1441'>
                                                            Bermuda (+1441)
                                                        </option>
                                                        <option data-countryCode='BT' value='975'>
                                                            Bhutan (+975)
                                                        </option>
                                                        <option data-countryCode='BO' value='591'>
                                                            Bolivia (+591)
                                                        </option>
                                                        <option data-countryCode='BA' value='387'>
                                                            Bosnia Herzegovina (+387)
                                                        </option>
                                                        <option data-countryCode='BW' value='267'>
                                                            Botswana (+267)
                                                        </option>
                                                        <option data-countryCode='BR' value='55'>
                                                            Brazil (+55)
                                                        </option>
                                                        <option data-countryCode='BN' value='673'>
                                                            Brunei (+673)
                                                        </option>
                                                        <option data-countryCode='BG' value='359'>
                                                            Bulgaria (+359)
                                                        </option>
                                                        <option data-countryCode='BF' value='226'>
                                                            Burkina Faso (+226)
                                                        </option>
                                                        <option data-countryCode='BI' value='257'>
                                                            Burundi (+257)
                                                        </option>
                                                        <option data-countryCode='KH' value='855'>
                                                            Cambodia (+855)
                                                        </option>
                                                        <option data-countryCode='CM' value='237'>
                                                            Cameroon (+237)
                                                        </option>
                                                        <option data-countryCode='CA' value='1'>
                                                            Canada (+1)
                                                        </option>
                                                        <option data-countryCode='CV' value='238'>
                                                            Cape Verde Islands (+238)
                                                        </option>
                                                        <option data-countryCode='KY' value='1345'>
                                                            Cayman Islands (+1345)
                                                        </option>
                                                        <option data-countryCode='CF' value='236'>
                                                            Central African Republic (+236)
                                                        </option>
                                                        <option data-countryCode='CL' value='56'>
                                                            Chile (+56)
                                                        </option>
                                                        <option data-countryCode='CN' value='86'>
                                                            China (+86)
                                                        </option>
                                                        <option data-countryCode='CO' value='57'>
                                                            Colombia (+57)
                                                        </option>
                                                        <option data-countryCode='KM' value='269'>
                                                            Comoros (+269)
                                                        </option>
                                                        <option data-countryCode='CG' value='242'>
                                                            Congo (+242)
                                                        </option>
                                                        <option data-countryCode='CK' value='682'>
                                                            Cook Islands (+682)
                                                        </option>
                                                        <option data-countryCode='CR' value='506'>
                                                            Costa Rica (+506)
                                                        </option>
                                                        <option data-countryCode='HR' value='385'>
                                                            Croatia (+385)
                                                        </option>
                                                        <option data-countryCode='CU' value='53'>
                                                            Cuba (+53)
                                                        </option>
                                                        <option data-countryCode='CY' value='90392'>
                                                            Cyprus North (+90392)
                                                        </option>
                                                        <option data-countryCode='CY' value='357'>
                                                            Cyprus South (+357)
                                                        </option>
                                                        <option data-countryCode='CZ' value='42'>
                                                            Czech Republic (+42)
                                                        </option>
                                                        <option data-countryCode='DK' value='45'>
                                                            Denmark (+45)
                                                        </option>
                                                        <option data-countryCode='DJ' value='253'>
                                                            Djibouti (+253)
                                                        </option>
                                                        <option data-countryCode='DM' value='1809'>
                                                            Dominica (+1809)
                                                        </option>
                                                        <option data-countryCode='DO' value='1809'>
                                                            Dominican Republic (+1809)
                                                        </option>
                                                        <option data-countryCode='EC' value='593'>
                                                            Ecuador (+593)
                                                        </option>
                                                        <option data-countryCode='EG' value='20'>
                                                            Egypt (+20)
                                                        </option>
                                                        <option data-countryCode='SV' value='503'>
                                                            El Salvador (+503)
                                                        </option>
                                                        <option data-countryCode='GQ' value='240'>
                                                            Equatorial Guinea (+240)
                                                        </option>
                                                        <option data-countryCode='ER' value='291'>
                                                            Eritrea (+291)
                                                        </option>
                                                        <option data-countryCode='EE' value='372'>
                                                            Estonia (+372)
                                                        </option>
                                                        <option data-countryCode='ET' value='251'>
                                                            Ethiopia (+251)
                                                        </option>
                                                        <option data-countryCode='FK' value='500'>
                                                            Falkland Islands (+500)
                                                        </option>
                                                        <option data-countryCode='FO' value='298'>
                                                            Faroe Islands (+298)
                                                        </option>
                                                        <option data-countryCode='FJ' value='679'>
                                                            Fiji (+679)
                                                        </option>
                                                        <option data-countryCode='FI' value='358'>
                                                            Finland (+358)
                                                        </option>
                                                        <option data-countryCode='FR' value='33'>
                                                            France (+33)
                                                        </option>
                                                        <option data-countryCode='GF' value='594'>
                                                            French Guiana (+594)
                                                        </option>
                                                        <option data-countryCode='PF' value='689'>
                                                            French Polynesia (+689)
                                                        </option>
                                                        <option data-countryCode='GA' value='241'>
                                                            Gabon (+241)
                                                        </option>
                                                        <option data-countryCode='GM' value='220'>
                                                            Gambia (+220)
                                                        </option>
                                                        <option data-countryCode='GE' value='7880'>
                                                            Georgia (+7880)
                                                        </option>
                                                        <option data-countryCode='DE' value='49'>
                                                            Germany (+49)
                                                        </option>
                                                        <option data-countryCode='GH' value='233'>
                                                            Ghana (+233)
                                                        </option>
                                                        <option data-countryCode='GI' value='350'>
                                                            Gibraltar (+350)
                                                        </option>
                                                        <option data-countryCode='GR' value='30'>
                                                            Greece (+30)
                                                        </option>
                                                        <option data-countryCode='GL' value='299'>
                                                            Greenland (+299)
                                                        </option>
                                                        <option data-countryCode='GD' value='1473'>
                                                            Grenada (+1473)
                                                        </option>
                                                        <option data-countryCode='GP' value='590'>
                                                            Guadeloupe (+590)
                                                        </option>
                                                        <option data-countryCode='GU' value='671'>
                                                            Guam (+671)
                                                        </option>
                                                        <option data-countryCode='GT' value='502'>
                                                            Guatemala (+502)
                                                        </option>
                                                        <option data-countryCode='GN' value='224'>
                                                            Guinea (+224)
                                                        </option>
                                                        <option data-countryCode='GW' value='245'>
                                                            Guinea - Bissau (+245)
                                                        </option>
                                                        <option data-countryCode='GY' value='592'>
                                                            Guyana (+592)
                                                        </option>
                                                        <option data-countryCode='HT' value='509'>
                                                            Haiti (+509)
                                                        </option>
                                                        <option data-countryCode='HN' value='504'>
                                                            Honduras (+504)
                                                        </option>
                                                        <option data-countryCode='HK' value='852'>
                                                            Hong Kong (+852)
                                                        </option>
                                                        <option data-countryCode='HU' value='36'>
                                                            Hungary (+36)
                                                        </option>
                                                        <option data-countryCode='IS' value='354'>
                                                            Iceland (+354)
                                                        </option>
                                                        <option data-countryCode='IN' value='91'>
                                                            India (+91)
                                                        </option>
                                                        <option data-countryCode='ID' value='62'>
                                                            Indonesia (+62)
                                                        </option>
                                                        <option data-countryCode='IR' value='98'>
                                                            Iran (+98)
                                                        </option>
                                                        <option data-countryCode='IQ' value='964'>
                                                            Iraq (+964)
                                                        </option>
                                                        <option data-countryCode='IE' value='353'>
                                                            Ireland (+353)
                                                        </option>
                                                        <option data-countryCode='IL' value='972'>
                                                            Israel (+972)
                                                        </option>
                                                        <option data-countryCode='IT' value='39'>
                                                            Italy (+39)
                                                        </option>
                                                        <option data-countryCode='JM' value='1876'>
                                                            Jamaica (+1876)
                                                        </option>
                                                        <option data-countryCode='JP' value='81'>
                                                            Japan (+81)
                                                        </option>
                                                        <option data-countryCode='JO' value='962'>
                                                            Jordan (+962)
                                                        </option>
                                                        <option data-countryCode='KZ' value='7'>
                                                            Kazakhstan (+7)
                                                        </option>
                                                        <option data-countryCode='KE' value='254'>
                                                            Kenya (+254)
                                                        </option>
                                                        <option data-countryCode='KI' value='686'>
                                                            Kiribati (+686)
                                                        </option>
                                                        <option data-countryCode='KP' value='850'>
                                                            Korea North (+850)
                                                        </option>
                                                        <option data-countryCode='KR' value='82'>
                                                            Korea South (+82)
                                                        </option>
                                                        <option data-countryCode='KW' value='965'>
                                                            Kuwait (+965)
                                                        </option>
                                                        <option data-countryCode='KG' value='996'>
                                                            Kyrgyzstan (+996)
                                                        </option>
                                                        <option data-countryCode='LA' value='856'>
                                                            Laos (+856)
                                                        </option>
                                                        <option data-countryCode='LV' value='371'>
                                                            Latvia (+371)
                                                        </option>
                                                        <option data-countryCode='LB' value='961'>
                                                            Lebanon (+961)
                                                        </option>
                                                        <option data-countryCode='LS' value='266'>
                                                            Lesotho (+266)
                                                        </option>
                                                        <option data-countryCode='LR' value='231'>
                                                            Liberia (+231)
                                                        </option>
                                                        <option data-countryCode='LY' value='218'>
                                                            Libya (+218)
                                                        </option>
                                                        <option data-countryCode='LI' value='417'>
                                                            Liechtenstein (+417)
                                                        </option>
                                                        <option data-countryCode='LT' value='370'>
                                                            Lithuania (+370)
                                                        </option>
                                                        <option data-countryCode='LU' value='352'>
                                                            Luxembourg (+352)
                                                        </option>
                                                        <option data-countryCode='MO' value='853'>
                                                            Macao (+853)
                                                        </option>
                                                        <option data-countryCode='MK' value='389'>
                                                            Macedonia (+389)
                                                        </option>
                                                        <option data-countryCode='MG' value='261'>
                                                            Madagascar (+261)
                                                        </option>
                                                        <option data-countryCode='MW' value='265'>
                                                            Malawi (+265)
                                                        </option>
                                                        <option data-countryCode='MY' value='60'>
                                                            Malaysia (+60)
                                                        </option>
                                                        <option data-countryCode='MV' value='960'>
                                                            Maldives (+960)
                                                        </option>
                                                        <option data-countryCode='ML' value='223'>
                                                            Mali (+223)
                                                        </option>
                                                        <option data-countryCode='MT' value='356'>
                                                            Malta (+356)
                                                        </option>
                                                        <option data-countryCode='MH' value='692'>
                                                            Marshall Islands (+692)
                                                        </option>
                                                        <option data-countryCode='MQ' value='596'>
                                                            Martinique (+596)
                                                        </option>
                                                        <option data-countryCode='MR' value='222'>
                                                            Mauritania (+222)
                                                        </option>
                                                        <option data-countryCode='YT' value='269'>
                                                            Mayotte (+269)
                                                        </option>
                                                        <option data-countryCode='MX' value='52'>
                                                            Mexico (+52)
                                                        </option>
                                                        <option data-countryCode='FM' value='691'>
                                                            Micronesia (+691)
                                                        </option>
                                                        <option data-countryCode='MD' value='373'>
                                                            Moldova (+373)
                                                        </option>
                                                        <option data-countryCode='MC' value='377'>
                                                            Monaco (+377)
                                                        </option>
                                                        <option data-countryCode='MN' value='976'>
                                                            Mongolia (+976)
                                                        </option>
                                                        <option data-countryCode='MS' value='1664'>
                                                            Montserrat (+1664)
                                                        </option>
                                                        <option data-countryCode='MA' value='212'>
                                                            Morocco (+212)
                                                        </option>
                                                        <option data-countryCode='MZ' value='258'>
                                                            Mozambique (+258)
                                                        </option>
                                                        <option data-countryCode='MN' value='95'>
                                                            Myanmar (+95)
                                                        </option>
                                                        <option data-countryCode='NA' value='264'>
                                                            Namibia (+264)
                                                        </option>
                                                        <option data-countryCode='NR' value='674'>
                                                            Nauru (+674)
                                                        </option>
                                                        <option data-countryCode='NP' value='977'>
                                                            Nepal (+977)
                                                        </option>
                                                        <option data-countryCode='NL' value='31'>
                                                            Netherlands (+31)
                                                        </option>
                                                        <option data-countryCode='NC' value='687'>
                                                            New Caledonia (+687)
                                                        </option>
                                                        <option data-countryCode='NZ' value='64'>
                                                            New Zealand (+64)
                                                        </option>
                                                        <option data-countryCode='NI' value='505'>
                                                            Nicaragua (+505)
                                                        </option>
                                                        <option data-countryCode='NE' value='227'>
                                                            Niger (+227)
                                                        </option>
                                                        <option data-countryCode='NG' value='234'>
                                                            Nigeria (+234)
                                                        </option>
                                                        <option data-countryCode='NU' value='683'>
                                                            Niue (+683)
                                                        </option>
                                                        <option data-countryCode='NF' value='672'>
                                                            Norfolk Islands (+672)
                                                        </option>
                                                        <option data-countryCode='NP' value='670'>
                                                            Northern Marianas (+670)
                                                        </option>
                                                        <option data-countryCode='NO' value='47'>
                                                            Norway (+47)
                                                        </option>
                                                        <option data-countryCode='OM' value='968'>
                                                            Oman (+968)
                                                        </option>
                                                        <option data-countryCode='PW' value='680'>
                                                            Palau (+680)
                                                        </option>
                                                        <option data-countryCode='PA' value='507'>
                                                            Panama (+507)
                                                        </option>
                                                        <option data-countryCode='PG' value='675'>
                                                            Papua New Guinea (+675)
                                                        </option>
                                                        <option data-countryCode='PY' value='595'>
                                                            Paraguay (+595)
                                                        </option>
                                                        <option data-countryCode='PE' value='51'>
                                                            Peru (+51)
                                                        </option>
                                                        <option data-countryCode='PH' value='63'>
                                                            Philippines (+63)
                                                        </option>
                                                        <option data-countryCode='PL' value='48'>
                                                            Poland (+48)
                                                        </option>
                                                        <option data-countryCode='PT' value='351'>
                                                            Portugal (+351)
                                                        </option>
                                                        <option data-countryCode='PR' value='1787'>
                                                            Puerto Rico (+1787)
                                                        </option>
                                                        <option data-countryCode='QA' value='974'>
                                                            Qatar (+974)
                                                        </option>
                                                        <option data-countryCode='RE' value='262'>
                                                            Reunion (+262)
                                                        </option>
                                                        <option data-countryCode='RO' value='40'>
                                                            Romania (+40)
                                                        </option>
                                                        <option data-countryCode='RU' value='7'>
                                                            Russia (+7)
                                                        </option>
                                                        <option data-countryCode='RW' value='250'>
                                                            Rwanda (+250)
                                                        </option>
                                                        <option data-countryCode='SM' value='378'>
                                                            San Marino (+378)
                                                        </option>
                                                        <option data-countryCode='ST' value='239'>
                                                            Sao Tome &amp; Principe (+239)
                                                        </option>
                                                        <option data-countryCode='SA' value='966'>
                                                            Saudi Arabia (+966)
                                                        </option>
                                                        <option data-countryCode='SN' value='221'>
                                                            Senegal (+221)
                                                        </option>
                                                        <option data-countryCode='CS' value='381'>
                                                            Serbia (+381)
                                                        </option>
                                                        <option data-countryCode='SC' value='248'>
                                                            Seychelles (+248)
                                                        </option>
                                                        <option data-countryCode='SL' value='232'>
                                                            Sierra Leone (+232)
                                                        </option>
                                                        <option data-countryCode='SG' value='65'>
                                                            Singapore (+65)
                                                        </option>
                                                        <option data-countryCode='SK' value='421'>
                                                            Slovak Republic (+421)
                                                        </option>
                                                        <option data-countryCode='SI' value='386'>
                                                            Slovenia (+386)
                                                        </option>
                                                        <option data-countryCode='SB' value='677'>
                                                            Solomon Islands (+677)
                                                        </option>
                                                        <option data-countryCode='SO' value='252'>
                                                            Somalia (+252)
                                                        </option>
                                                        <option data-countryCode='ZA' value='27'>
                                                            South Africa (+27)
                                                        </option>
                                                        <option data-countryCode='ES' value='34'>
                                                            Spain (+34)
                                                        </option>
                                                        <option data-countryCode='LK' value='94'>
                                                            Sri Lanka (+94)
                                                        </option>
                                                        <option data-countryCode='SH' value='290'>
                                                            St. Helena (+290)
                                                        </option>
                                                        <option data-countryCode='KN' value='1869'>
                                                            St. Kitts (+1869)
                                                        </option>
                                                        <option data-countryCode='SC' value='1758'>
                                                            St. Lucia (+1758)
                                                        </option>
                                                        <option data-countryCode='SD' value='249'>
                                                            Sudan (+249)
                                                        </option>
                                                        <option data-countryCode='SR' value='597'>
                                                            Suriname (+597)
                                                        </option>
                                                        <option data-countryCode='SZ' value='268'>
                                                            Swaziland (+268)
                                                        </option>
                                                        <option data-countryCode='SE' value='46'>
                                                            Sweden (+46)
                                                        </option>
                                                        <option data-countryCode='CH' value='41'>
                                                            Switzerland (+41)
                                                        </option>
                                                        <option data-countryCode='SI' value='963'>
                                                            Syria (+963)
                                                        </option>
                                                        <option data-countryCode='TW' value='886'>
                                                            Taiwan (+886)
                                                        </option>
                                                        <option data-countryCode='TJ' value='7'>
                                                            Tajikstan (+7)
                                                        </option>
                                                        <option data-countryCode='TH' value='66'>
                                                            Thailand (+66)
                                                        </option>
                                                        <option data-countryCode='TG' value='228'>
                                                            Togo (+228)
                                                        </option>
                                                        <option data-countryCode='TO' value='676'>
                                                            Tonga (+676)
                                                        </option>
                                                        <option data-countryCode='TT' value='1868'>
                                                            Trinidad &amp; Tobago (+1868)
                                                        </option>
                                                        <option data-countryCode='TN' value='216'>
                                                            Tunisia (+216)
                                                        </option>
                                                        <option data-countryCode='TR' value='90'>
                                                            Turkey (+90)
                                                        </option>
                                                        <option data-countryCode='TM' value='7'>
                                                            Turkmenistan (+7)
                                                        </option>
                                                        <option data-countryCode='TM' value='993'>
                                                            Turkmenistan (+993)
                                                        </option>
                                                        <option data-countryCode='TC' value='1649'>
                                                            Turks &amp; Caicos Islands (+1649)
                                                        </option>
                                                        <option data-countryCode='TV' value='688'>
                                                            Tuvalu (+688)
                                                        </option>
                                                        <option data-countryCode='UG' value='256'>
                                                            Uganda (+256)
                                                        </option>
                                                        <option data-countryCode='UA' value='380'>
                                                            Ukraine (+380)
                                                        </option>
                                                        <option data-countryCode='AE' value='971'>
                                                            United Arab Emirates (+971)
                                                        </option>
                                                        <option data-countryCode='UY' value='598'>
                                                            Uruguay (+598)
                                                        </option>
                                                        <option data-countryCode='UZ' value='7'>
                                                            Uzbekistan (+7)
                                                        </option>
                                                        <option data-countryCode='VU' value='678'>
                                                            Vanuatu (+678)
                                                        </option>
                                                        <option data-countryCode='VA' value='379'>
                                                            Vatican City (+379)
                                                        </option>
                                                        <option data-countryCode='VE' value='58'>
                                                            Venezuela (+58)
                                                        </option>
                                                        <option data-countryCode='VN' value='84'>
                                                            Vietnam (+84)
                                                        </option>
                                                        <option data-countryCode='VG' value='84'>
                                                            Virgin Islands - British (+1284)
                                                        </option>
                                                        <option data-countryCode='VI' value='84'>
                                                            Virgin Islands - US (+1340)
                                                        </option>
                                                        <option data-countryCode='WF' value='681'>
                                                            Wallis &amp; Futuna (+681)
                                                        </option>
                                                        <option data-countryCode='YE' value='969'>
                                                            Yemen (North)(+969)
                                                        </option>
                                                        <option data-countryCode='YE' value='967'>
                                                            Yemen (South)(+967)
                                                        </option>
                                                        <option data-countryCode='ZM' value='260'>
                                                            Zambia (+260)
                                                        </option>
                                                        <option data-countryCode='ZW' value='263'>
                                                            Zimbabwe (+263)
                                                        </option>
                                                    </optgroup>
                                                </select>
                                            </div>

                                            <div className='col-3 form-group'>
                                                <label>Agent Phone Number:</label>
                                                <input value={this.state.profile.agent_phone_number} onChange={(e) => this.setState({ profile: { ...this.state.profile, agent_phone_number: e.target.value } })} type='tel' className='form-control' />
                                            </div>

                                            <div className='col-3 form-group'>
                                                <label>Agent Email:</label>
                                                <input value={this.state.profile.agent_email} onChange={(e) => this.setState({ profile: { ...this.state.profile, agent_email: e.target.value } })} type='email' className='form-control' placeholder='Agent Email...' />
                                            </div>

                                            <div className='col-12'></div>
                                            <div className='col-3'>
                                                <button name='modal-health-submit' className='py-2 btn btn-warning w-100' onClick={() => this.setState({ updating: false })}>
                                                    Cancel
                                                </button>
                                            </div>
                                            <div className='col-9'>
                                                <button name='modal-health-submit' className='py-2 btn btn-success w-100' onClick={() => this.saveProfile()}>
                                                    Update
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    renderUpLoadFile() {
        if (!this.state.uploaded) return <></>
        return (
            <>
                <div onClick={() => this.setState({ uploaded: false })} style={{ position: 'fixed', width: '100vw', height: '100vh', background: 'rgba(0,0,0,0.5)', zIndex: '100', top: '0', left: '0' }}></div>
                <div style={{ position: 'fixed', width: '100vw', height: '100vh', zIndex: '100', top: '0', left: '0' }}>
                    <div style={{ position: 'relative', maxWidth: '80vw', zIndex: '102', top: '100px', margin: 'auto' }}>
                        <div className='mx-auto border-0 card' style={{ maxHeight: '80vh', overflowY: 'scroll' }}>
                            <div className='p-4 card-body'>
                                <h3 className='card-title font-body'>Bulk upload</h3>
                                <div className='row'>
                                    <label
                                        for='customFileInput'
                                        class='custom-file-upload'
                                        style={{
                                            border: '2px solid #ccc',
                                            display: 'inline-block',
                                            padding: '6px 12px',
                                            cursor: 'pointer',
                                            width: '100%',
                                            height: '200px',
                                            // margin:"10px ",
                                            ml: '20%',
                                            border: '1px dashed blue',
                                            borderRadius: '4px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <p> {this.state.fileName ? this.state.fileName : 'click to upload csv file'} </p>
                                    </label>
                                    <input
                                        type='file'
                                        id='customFileInput'
                                        class='input-file'
                                        accept='.csv'
                                        onChange={this.handleFileInputChange}
                                        style={{
                                            display: 'none',
                                        }}
                                    ></input>
                                </div>

                                <div className='row my-2'>
                                    <div className='col-3'>
                                        <button name='modal-health-submit' className='py-2 ml-3 btn btn-warning w-100' onClick={() => this.setState({ uploaded: false })}>
                                            Cancel
                                        </button>
                                    </div>
                                    <div className='col-9'>
                                        <button name='modal-health-submit' disabled={this.state.busy} className='py-2 btn btn-success w-100' onClick={() => this.uploadFile()}>
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    renderJobListingModal() {
        if (!this.state.adding_job_listing) return <></>
        return (
            <>
                <div onClick={() => this.setState({ adding_job_listing: false })} style={{ position: 'fixed', width: '100vw', height: '100vh', background: 'rgba(0,0,0,0.5)', zIndex: '100', top: '0', left: '0' }}></div>
                <div style={{ position: 'fixed', width: '100vw', height: '100vh', zIndex: '100', top: '0', left: '0' }}>
                    <div style={{ position: 'relative', maxWidth: '80vw', zIndex: '102', top: '100px', margin: 'auto' }}>
                        <div className='mx-auto border-0 card' style={{ maxHeight: '80vh', overflowY: 'scroll' }}>
                            <div className='p-4 card-body'>
                                <h3 className='card-title font-body'>Add New Job Listing</h3>
                                <div className='form-widget'>
                                    <div className='form-result'></div>

                                    <div className='mb-0 position-relative'>
                                        <div className='form-process'>
                                            <div className='css3-spinner'>
                                                <div className='css3-spinner-scaler'></div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 form-group'>
                                                <label>Job:</label>
                                                <Select
                                                    isSearchable={true}
                                                    options={[].concat.apply(
                                                        [],
                                                        this.state.categories.map((x) =>
                                                            x.jobs.map((x) => {
                                                                return { label: x, value: x }
                                                            })
                                                        )
                                                    )}
                                                    value={{ label: this.state.job_listing.job, value: this.state.job_listing.job }}
                                                    onChange={(e) => this.setState({ job_listing: { ...this.state.job_listing, job: e.value } })}
                                                ></Select>
                                            </div>
                                            <div className='col-6 form-group'>
                                                <label>Location:</label>
                                                <input value={this.state.job_listing.location} onChange={(e) => this.setState({ job_listing: { ...this.state.job_listing, location: e.target.value } })} type='text' className='form-control' placeholder='Location...' />
                                            </div>
                                            <div className='col-6 form-group'>
                                                <label>End Ad. On Date:</label>
                                                <input value={this.state.job_listing.closing_date} onChange={(e) => this.setState({ job_listing: { ...this.state.job_listing, closing_date: e.target.value } })} type='date' className='form-control' />
                                            </div>
                                            <div className='col-12 form-group'>
                                                <label>Details:</label>
                                                <textarea rows='3' value={this.state.job_listing.description} onChange={(e) => this.setState({ job_listing: { ...this.state.job_listing, description: e.target.value } })} type='text' className='form-control' placeholder='Details...' />
                                            </div>
                                            <div className='col-12'></div>
                                            <div className='col-3'>
                                                <button name='modal-health-submit' className='py-2 btn btn-warning w-100' onClick={() => this.setState({ adding_job_listing: false })}>
                                                    Cancel
                                                </button>
                                            </div>
                                            <div className='col-9'>
                                                <button name='modal-health-submit' className='py-2 btn btn-success w-100' onClick={() => this.saveJobListing()}>
                                                    Update
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    renderJobApplicationModal() {
        if (!this.state.adding_job_application) return <></>
        return (
            <>
                <div onClick={() => this.setState({ adding_job_application: false })} style={{ position: 'fixed', width: '100vw', height: '100vh', background: 'rgba(0,0,0,0.5)', zIndex: '100', top: '0', left: '0' }}></div>
                <div style={{ position: 'fixed', width: '100vw', height: '100vh', zIndex: '100', top: '0', left: '0' }}>
                    <div style={{ position: 'relative', maxWidth: '80vw', zIndex: '102', top: '100px', margin: 'auto' }}>
                        <div className='mx-auto border-0 card' style={{ maxHeight: '80vh', overflowY: 'scroll' }}>
                            <div className='p-4 card-body'>
                                <h3 className='card-title font-body'>Apply To Job</h3>
                                <div className='form-widget'>
                                    <div className='form-result'></div>

                                    <div className='mb-0 position-relative'>
                                        <div className='form-process'>
                                            <div className='css3-spinner'>
                                                <div className='css3-spinner-scaler'></div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-4 form-group'>
                                                <label>Phone Number:</label>
                                                <input value={this.state.job_application.phone_number} onChange={(e) => this.setState({ job_application: { ...this.state.job_application, phone_number: e.target.value } })} type='text' className='form-control' placeholder='Phone Number...' />
                                            </div>
                                            <div className='col-4 form-group'>
                                                <label>Email:</label>
                                                <input value={this.state.job_application.email} onChange={(e) => this.setState({ job_application: { ...this.state.job_application, email: e.target.value } })} type='text' className='form-control' placeholder='Email...' />
                                            </div>
                                            <div className='col-4 form-group'>
                                                <label>Location:</label>
                                                <input value={this.state.job_application.location} onChange={(e) => this.setState({ job_application: { ...this.state.job_application, location: e.target.value } })} type='text' className='form-control' placeholder='Location...' />
                                            </div>
                                            <div className='col-12 form-group'>
                                                <label>Details:</label>
                                                <p>Include links to websites and portfolios (Dropbox and WeTransfer links, etc.) as well as a written description of your experience and qualifications.</p>
                                                <textarea rows='3' value={this.state.job_application.application_details} onChange={(e) => this.setState({ job_application: { ...this.state.job_application, application_details: e.target.value } })} type='text' className='form-control' placeholder='Details...' />
                                            </div>
                                            <div className='col-12 form-group'>
                                                <label>Experience:</label>
                                                <textarea rows='3' value={this.state.job_application.experience} onChange={(e) => this.setState({ job_application: { ...this.state.job_application, experience: e.target.value } })} type='text' className='form-control' placeholder='Experience...' />
                                            </div>
                                            <div className='col-12'></div>
                                            <div className='col-3'>
                                                <button name='modal-health-submit' className='py-2 btn btn-warning w-100' onClick={() => this.setState({ adding_job_application: false })}>
                                                    Cancel
                                                </button>
                                            </div>
                                            <div className='col-9'>
                                                <button name='modal-health-submit' className='py-2 btn btn-success w-100' onClick={() => this.saveJobApplication()}>
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    renderInviteModal() {
        const baseUrl = window.location.origin;
        if (!this.state.showInviteModal) return <></>
        return (
            <>
                <div onClick={() => this.setState({ showInviteModal: false })} style={{ position: 'fixed', width: '100vw', height: '100vh', background: 'rgba(0,0,0,0.5)', zIndex: '100', top: '0', left: '0' }}></div>
                <div style={{ position: 'fixed', width: '100vw', height: '100vh', zIndex: '100', top: '0', left: '0' }}>
                    <div style={{ position: 'relative', maxWidth: '80vw', zIndex: '102', top: '100px', margin: 'auto' }}>
                        <div className='mx-auto border-0 card' style={{ maxHeight: '80vh', overflowY: 'scroll' }}>
                            <div className='p-4 card-body'>
                                <h3 className='card-title font-body'>Invite a new user to Circlu8!</h3>
                                <div className='form-widget'>
                                    <div className='mb-0 position-relative'>
                                        <div class='row'>
                                            <div className='col-12'>
                                                <p>Send this link to others to invite them to join Circul8!</p>
                                                <p>
                                                    <a className='text-[#1ABC9C]' href={`${baseUrl}/register?invite=${this.state.inviteCode}`}>
                                                        {baseUrl}/register?invite={this.state.inviteCode}
                                                    </a>
                                                </p>
                                            </div>
                                            <div className='col-3'>
                                                <button name='modal-health-submit' className='py-2 btn btn-warning w-100' onClick={() => this.setState({ showInviteModal: false })}>
                                                    Close
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    getLinkHref(link) {
        //https://
        if (!link) return ''
        if (link.substring(0, 8) != 'https://') {
            return 'https://' + link
        }
        return link
    }

    addToCircle(index) {
        fetch(process.env.REACT_APP_API_URL + '/public/add-to-circle/' + this.state.id + '/' + index, {
            method: 'GET',
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.token,
            }),
        })
            .then((res) => {
                if (res.status === 401) window.location.replace('/login')
                return res.json()
            })
            .then((res) => this.setState({ metaData: res.meta }))
    }

    addToCircleInvited(index, id) {
        fetch(process.env.REACT_APP_API_URL + '/public/add-to-circle-suggested/' + id + '/' + index, {
            method: 'GET',
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.token,
            }),
        })
            .then((res) => {
                if (res.status === 401) window.location.replace('/login')
                return res.json()
            })
            .then((res) => this.setState({ metaData: res.meta, circles: res.circles, invitedUsers: res.linkedUsers }))
    }

    removeFromCircle() {
        fetch(process.env.REACT_APP_API_URL + '/public/remove-from-circle/' + this.state.id, {
            method: 'GET',
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.token,
            }),
        })
            .then((res) => {
                if (res.status === 401) window.location.replace('/login')
                return res.json()
            })
            .then((res) => this.setState({ metaData: res.meta }))
    }

    render() {
        if (!this.state.isLoaded) {
            return 'Loading...'
        }
        console.log('state', this.state)

        return (
            <div className='grid w-full grid-cols-4 p-10 m-auto bg-white rounded-lg max-w-7xl'>
                {this.renderEditModal()}
                {this.renderJobListingModal()}
                {this.renderJobApplicationModal()}
                {this.renderInviteModal()}
                {this.renderUpLoadFile()}
                <div className='col-span-3'>
                    <div className='border-0 heading-block'>
                        <h3>
                            {this.state.profile.first_name} {this.state.profile.middle_names} {this.state.profile.last_name}
                        </h3>
                        <span>{this.state.profile.job}</span>
                        <br />
                        <span>{this.state.profile.bio}</span>
                        {!this.state.profile.phone_number_private && (
                            <p>
                                {this.state.profile.phone_country_code ? this.state.profile.phone_country_code : '+44'} {this.state.profile.phone_number}{' '}
                            </p>
                        )}
                    </div>

                    <div className='clear'></div>

                    <div className='clearfix row'>
                        <div className='col-lg-12'>
                            <div className='clearfix tabs tabs-alt' id='tabs-profile'>
                                <ul className='clearfix tab-nav'>
                                    <li class={`${this.state.selectedTab === 0 ? 'ui-tabs-tab ui-corner-top ui-state-default ui-tab ui-tabs-active ui-state-active' : ''}`}>
                                        <a style={{ cursor: 'pointer' }} onClick={() => this.setState({ selectedTab: 0 })}>
                                            <i className='icon-pencil2'></i> Info
                                        </a>
                                    </li>
                                    <li class={`${this.state.selectedTab === 1 ? 'ui-tabs-tab ui-corner-top ui-state-default ui-tab ui-tabs-active ui-state-active' : ''}`}>
                                        <a style={{ cursor: 'pointer' }} onClick={() => this.setState({ selectedTab: 1 })}>
                                            <i className='icon-users'></i> Family ({this.state.circles[0].length}/25)
                                        </a>
                                    </li>
                                    <li class={`${this.state.selectedTab === 2 ? 'ui-tabs-tab ui-corner-top ui-state-default ui-tab ui-tabs-active ui-state-active' : ''}`}>
                                        <a style={{ cursor: 'pointer' }} onClick={() => this.setState({ selectedTab: 2 })}>
                                            <i className='icon-users'></i> Friends ({this.state.circles[1].length}/125)
                                        </a>
                                    </li>
                                    <li class={`${this.state.selectedTab === 3 ? 'ui-tabs-tab ui-corner-top ui-state-default ui-tab ui-tabs-active ui-state-active' : ''}`}>
                                        <a style={{ cursor: 'pointer' }} onClick={() => this.setState({ selectedTab: 3 })}>
                                            <i className='icon-users'></i> Acquaintances ({this.state.circles[2].length}/450)
                                        </a>
                                    </li>
                                    {this.state.metaData.isOwnProfile && (
                                        <li class={`${this.state.selectedTab === 4 ? 'ui-tabs-tab ui-corner-top ui-state-default ui-tab ui-tabs-active ui-state-active' : ''}`}>
                                            <a style={{ cursor: 'pointer' }} onClick={() => this.setState({ selectedTab: 4 })}>
                                                <i className='icon-users'></i> Suggestions ({this.state.invitedUsers ? this.state.invitedUsers.filter((y) => y.fields.first_name).length : 0})
                                            </a>
                                        </li>
                                    )}
                                    <li class={`${this.state.selectedTab === 5 ? 'ui-tabs-tab ui-corner-top ui-state-default ui-tab ui-tabs-active ui-state-active' : ''}`}>
                                        <a style={{ cursor: 'pointer' }} onClick={() => this.setState({ selectedTab: 5 })}>
                                            <i className='icon-users'></i> Job Listings ({this.state.job_listings ? this.state.job_listings.filter((y) => this.state.metaData.isOwnProfile || new Date(y.fields.closing_date) > Date.now()).length : 0})
                                        </a>
                                    </li>
                                    {this.state.metaData.isOwnProfile && (
                                        <li class={`${this.state.selectedTab === 6 ? 'ui-tabs-tab ui-corner-top ui-state-default ui-tab ui-tabs-active ui-state-active' : ''}`}>
                                            <a style={{ cursor: 'pointer' }} onClick={() => this.setState({ selectedTab: 6 })}>
                                                <i className='icon-users'></i> Job Applications ({this.state.job_applications ? this.state.job_applications.length : 0})
                                            </a>
                                        </li>
                                    )}
                                </ul>

                                <div className='w-full tab-container'>
                                    {this.state.selectedTab === 0 && (
                                        <div className='clearfix tab-content' id='tab-feeds'>
                                            <p>{this.state.profile.address}</p>
                                            {this.state.profile.agent_name && <p>Agent Name: {this.state.profile.agent_name}</p>}
                                            {this.state.profile.agent_email && <p>Agent Email: {this.state.profile.agent_email}</p>}
                                            {this.state.profile.agent_phone_number && (
                                                <p>
                                                    Agent Phone: {this.state.profile.agent_phone_country_code ? this.state.profile.agent_phone_country_code : '+44'}
                                                    {this.state.profile.agent_phone_number}
                                                </p>
                                            )}
                                        </div>
                                    )}
                                    {this.state.selectedTab === 1 && (
                                        <div className='clearfix w-full tab-content' id='tab-family'>
                                            <h2>Family</h2>
                                            <table class='table mb-0'>
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Job</th>
                                                        <th>Bio</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.circles[0].map((y) => (
                                                        <tr>
                                                            <td>
                                                                <a href={'/profile?id=' + y._id}>
                                                                    {y.fields.first_name} {y.fields.middle_names} {y.fields.last_name}
                                                                </a>{' '}
                                                            </td>
                                                            <td>{y.fields.job}</td>
                                                            <td>{y.fields.bio}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                    {this.state.selectedTab === 2 && (
                                        <div className='clearfix tab-content' id='tab-friends'>
                                            <h2>Friends</h2>
                                            <table class='table mb-0'>
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Job</th>
                                                        <th>Bio</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.circles[1].map((y) => (
                                                        <tr>
                                                            <td>
                                                                <a href={'/profile?id=' + y._id}>
                                                                    {y.fields.first_name} {y.fields.middle_names} {y.fields.last_name}
                                                                </a>{' '}
                                                            </td>
                                                            <td>{y.fields.job}</td>
                                                            <td>{y.fields.bio}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                    {this.state.selectedTab === 3 && (
                                        <div className='clearfix tab-content' id='tab-acquaintances'>
                                            <h2>Acquaintances</h2>
                                            <table class='table mb-0'>
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Job</th>
                                                        <th>Bio</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.circles[2].map((y) => (
                                                        <tr>
                                                            <td>
                                                                <a href={'/profile?id=' + y._id}>
                                                                    {y.fields.first_name} {y.fields.middle_names} {y.fields.last_name}
                                                                </a>{' '}
                                                            </td>
                                                            <td>{y.fields.job}</td>
                                                            <td>{y.fields.bio}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                    {this.state.selectedTab === 4 && (
                                        <div className='clearfix tab-content' id='tab-suggestions'>
                                            <h2>Suggestions</h2>
                                            <table class='table mb-0'>
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Job</th>
                                                        <th>Bio</th>
                                                        <th>Add</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.invitedUsers
                                                        .filter((y) => y.fields.first_name)
                                                        .map((y) => (
                                                            <tr>
                                                                <td>
                                                                    <a href={'/profile?id=' + y._id}>
                                                                        {y.fields.first_name} {y.fields.middle_names} {y.fields.last_name}
                                                                    </a>{' '}
                                                                </td>
                                                                <td>{y.fields.job}</td>
                                                                <td>{y.fields.bio}</td>
                                                                <td>
                                                                    <a onClick={() => this.addToCircleInvited(1, y._id)} class='button button-rounded button-reveal button-small button-border text-end'>
                                                                        <i class='icon-plus'></i>
                                                                        <span>Family ({this.state.circles[0].length}/25)</span>
                                                                    </a>
                                                                    <a onClick={() => this.addToCircleInvited(2, y._id)} class='button button-rounded button-reveal button-small button-border text-end'>
                                                                        <i class='icon-plus'></i>
                                                                        <span>Friends ({this.state.circles[1].length}/125)</span>
                                                                    </a>
                                                                    <a onClick={() => this.addToCircleInvited(3, y._id)} class='button button-rounded button-reveal button-small button-border text-end'>
                                                                        <i class='icon-plus'></i>
                                                                        <span>Acquaintances ({this.state.circles[2].length}/450)</span>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                    {this.state.selectedTab === 5 && (
                                        <div className='clearfix tab-content' id='tab-suggestions'>
                                            <h2>Job Listings</h2>
                                            <table class='table mb-0'>
                                                <thead>
                                                    <tr>
                                                        <th>Name/Edit</th>
                                                        <th>Location</th>
                                                        <th>Description</th>
                                                        <th>Closing Date</th>
                                                        {!this.state.metaData.isOwnProfile && <th>Apply</th>}
                                                        {this.state.metaData.isOwnProfile && <th>Delete</th>}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.job_listings
                                                        .filter((y) => this.state.metaData.isOwnProfile || new Date(y.fields.closing_date) > Date.now())
                                                        .map((y) => (
                                                            <tr style={new Date(y.fields.closing_date) > Date.now() ? {} : { opacity: '30%' }}>
                                                                <td>
                                                                    <span style={this.state.metaData.isOwnProfile ? { textDecoration: 'underline', cursor: 'pointer' } : {}} onClick={() => (this.state.metaData.isOwnProfile ? this.setState({ job_listing: y.fields, adding_job_listing: true, job_listing_edit_id: y._id }) : {})}>
                                                                        {y.fields.job}
                                                                    </span>{' '}
                                                                </td>
                                                                <td>{y.fields.location}</td>
                                                                <td>{y.fields.description}</td>
                                                                <td>{new Date(y.fields.closing_date).toDateString()}</td>
                                                                {!this.state.metaData.isOwnProfile && (
                                                                    <td>
                                                                        <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => this.setState({ adding_job_application: true, job_application_id: y._id })}>
                                                                            Apply
                                                                        </span>
                                                                    </td>
                                                                )}
                                                                {this.state.metaData.isOwnProfile && (
                                                                    <td>
                                                                        <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => this.deleteJobListing(y._id)}>
                                                                            Delete
                                                                        </span>
                                                                    </td>
                                                                )}
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                    {this.state.selectedTab === 6 && (
                                        <div className='clearfix tab-content' id='tab-suggestions'>
                                            <h2>Job Applications</h2>
                                            <table class='table mb-0'>
                                                <thead>
                                                    <tr>
                                                        <th>Job</th>
                                                        <th>Name</th>
                                                        <th>Details</th>
                                                        <th>Experience</th>
                                                        <th>Location</th>
                                                        <th>Phone</th>
                                                        <th>Email</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.job_applications.map((y) => (
                                                        <tr>
                                                            <td>{y.fields.job_name}</td>
                                                            <td>
                                                                <a href={'/profile?id=' + y.fields.user}>{y.fields.user_name}</a>
                                                            </td>
                                                            <td>{y.fields.application_details}</td>
                                                            <td>{y.fields.experience}</td>
                                                            <td>{y.fields.location}</td>
                                                            <td>{y.fields.phone_number}</td>
                                                            <td>{y.fields.email}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='w-full'>
                    <div class='list-group'>
                        {this.state.metaData.isOwnProfile && (
                            <a key={'a1'} onClick={() => this.setState({ updating: true })} class='list-group-item list-group-item-action d-flex justify-content-between cursor-pointer'>
                                <div>Update Details</div>
                                <i class='icon-cog'></i>
                            </a>
                        )}
                        {this.state.metaData.isOwnProfile && this.state.metaData.userRole === 0 ? (
                            <a key={'a1'} onClick={() => this.setState({ uploaded: true })} class='list-group-item list-group-item-action d-flex justify-content-between cursor-pointer'>
                                <div>Upload File</div>
                                <i class='icon-plus'></i>
                            </a>
                        ) : (
                            ''
                        )}
                        {this.state.metaData.isOwnProfile && (
                            <a key={'ajob'} onClick={() => this.setState({ adding_job_listing: true, job_listing: {}, job_listing_edit_id: null })} class='list-group-item list-group-item-action d-flex justify-content-between cursor-pointer'>
                                <div>Add Job Listing</div>
                                <i class='icon-plus'></i>
                            </a>
                        )}
                        {this.state.metaData.isOwnProfile && (
                            <a onClick={() => this.setState({ showInviteModal: true })} class='list-group-item list-group-item-action d-flex justify-content-between cursor-pointer'>
                                <div>Invite Someone New</div>
                                <i class='icon-share'></i>
                            </a>
                        )}
                        {!this.state.metaData.isOwnProfile && (
                            <a key={'a2'} onClick={() => this.addToCircle(1)} class={`list-group-item list-group-item-action d-flex justify-content-between cursor-pointer ${this.state.metaData.inCircle == 1 ? 'bg-primary text-white' : ''}`}>
                                <div>
                                    {this.state.metaData.inCircle == 1 ? 'In' : this.state.metaData.inCircle > 0 ? 'Move to' : 'Add to'} Family ({this.state.metaData.circleCounts[0]}/25)
                                </div>
                                {this.state.metaData.inCircle != 1 && <i class='icon-plus'></i>}
                            </a>
                        )}
                        {!this.state.metaData.isOwnProfile && (
                            <a key={'a3'} onClick={() => this.addToCircle(2)} class={`list-group-item list-group-item-action d-flex justify-content-between cursor-pointer ${this.state.metaData.inCircle == 2 ? 'bg-primary text-white' : ''}`}>
                                <div>
                                    {this.state.metaData.inCircle == 2 ? 'In' : this.state.metaData.inCircle > 0 ? 'Move to' : 'Add to'} Friends ({this.state.metaData.circleCounts[1]}/125)
                                </div>
                                {this.state.metaData.inCircle != 2 && <i class='icon-plus'></i>}
                            </a>
                        )}
                        {!this.state.metaData.isOwnProfile && (
                            <a key={'a4'} onClick={() => this.addToCircle(3)} class={`list-group-item list-group-item-action d-flex justify-content-between cursor-pointer ${this.state.metaData.inCircle == 3 ? 'bg-primary text-white' : ''}`}>
                                <div>
                                    {this.state.metaData.inCircle == 3 ? 'In' : this.state.metaData.inCircle > 0 ? 'Move to' : 'Add to'} Acquaintances ({this.state.metaData.circleCounts[2]}/450)
                                </div>
                                {this.state.metaData.inCircle != 3 && <i class='icon-plus'></i>}
                            </a>
                        )}
                        {!this.state.metaData.isOwnProfile && this.state.metaData.inCircle > 0 && (
                            <a key={'a5'} onClick={() => this.removeFromCircle()} class='list-group-item list-group-item-action d-flex justify-content-between cursor-pointer bg-danger text-white'>
                                <div>Remove from Circle</div>
                                <i class='icon-minus'></i>
                            </a>
                        )}
                    </div>
                    <div className='fancy-title topmargin title-border'>
                        <h4>Work</h4>
                    </div>

                    {this.state.profile.available_for_work && <div>Available for work!</div>}
                    {!this.state.profile.available_for_work && <div>Not available for work</div>}
                    {this.state.profile.available_from_date && <div>Available From {new Date(this.state.profile.available_from_date).toDateString()}</div>}
                    {this.state.profile.location && <div>Available In {this.state.profile.location}</div>}

                    <div className='fancy-title topmargin title-border'>
                        <h4>Links</h4>
                    </div>

                    <a style={{ cursor: 'pointer' }} target='_blank' href={this.getLinkHref(this.state.profile.linkedin_url)} className='social-icon si-linkedin si-small si-rounded si-light' title='LinkedIn'>
                        <i className='icon-linkedin'></i>
                        <i className='icon-linkedin'></i>
                    </a>

                    <a style={{ cursor: 'pointer' }} target='_blank' href={this.getLinkHref(this.state.profile.twitter_url)} className='social-icon si-twitter si-small si-rounded si-light' title='Twitter'>
                        <i className='icon-twitter'></i>
                        <i className='icon-twitter'></i>
                    </a>

                    <a style={{ cursor: 'pointer' }} target='_blank' href={this.getLinkHref(this.state.profile.imdb_url)} className='social-icon si-imdb si-small si-rounded si-light' title='IMDB'>
                        <i className='icon-imdb'></i>
                        <i className='icon-imdb'></i>
                    </a>
                    <br />
                    <br />
                    <div>
                        <a target='_blank' href={this.getLinkHref(this.state.profile.website_url)}>
                            Website
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}